import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { DICClassificationKey, InteractionClassificationData } from './types';

/** Ordered to be displayed in Classification Key menu */
export const interactionClassifications: InteractionClassificationData[] = [
  {
    classificationKey: DICClassificationKey.Contraindicated,
    label: i18n.t('dic:Contraindicated'),
    description: i18n.t('dic:contraindicateDesc'),
    color: palette.darkRed,
  },
  {
    classificationKey: DICClassificationKey.Major,
    label: i18n.t('dic:major'),
    description: i18n.t('dic:majorDesc'),
    color: palette.darkOrange,
  },
  {
    classificationKey: DICClassificationKey.Moderate,
    label: i18n.t('dic:moderate'),
    description: i18n.t('dic:moderateDesc'),
    color: palette.orange,
  },
  {
    classificationKey: DICClassificationKey.Minor,
    label: i18n.t('dic:minor'),
    description: i18n.t('dic:minorDesc'),
    color: palette.yellow,
  },
  {
    classificationKey: DICClassificationKey.NoKnownInteraction,
    label: i18n.t('dic:noKnown'),
    description: i18n.t('dic:noKnownDesc'),
    color: palette.green,
  },
  {
    classificationKey: DICClassificationKey.NoData,
    label: i18n.t('dic:noData'),
    description: i18n.t('dic:noDataDesc'),
    color: palette.grey6,
  },
];
