import { GroupRole } from 'src/features/auth/enums';
import { i18n } from 'src/locale';

import {
  FetchPracticeLocationResponse,
  GroupMember,
  MixedGroupLicencesResponse,
  NumberOfLicencesResponse,
  PracticeLocation,
  RegularGroupLicencesResponse,
} from './types';

export function isRegularGroupLicencesResponse(
  payload: NumberOfLicencesResponse,
): payload is RegularGroupLicencesResponse {
  return 'numberOfLicences' in payload;
}

export function isMixedGroupLicencesResponse(
  payload: NumberOfLicencesResponse,
): payload is MixedGroupLicencesResponse {
  return 'numberOfAvailableBasicLicences' in payload;
}

export const getMemberLicenseTextOld = (member: GroupMember) => {
  switch (member.accessLevel) {
    case 'pro-limited':
      return '-';
    case 'basic':
      return i18n.t('pro');
    case 'pro':
      return i18n.t('basic');
  }
};

export const getMemberLicenseText = (member: GroupMember) => {
  switch (member.accessLevel) {
    case 'pro-limited':
      return '-';
    case 'basic':
      return i18n.t('plumbs');
    case 'pro':
      return i18n.t('standards');
  }
};

export const getUserRoleTranslationKey = (member: GroupMember) => {
  const { role } = member;

  if (role === GroupRole.ADMIN) {
    return 'adminDashboard:groupAdmin';
  }
  if (role === GroupRole.SUPPORT_STAFF) {
    return 'adminDashboard:supportStaff';
  }

  return 'adminDashboard:member';
};

export function parsePracticeLocationResponse(response: FetchPracticeLocationResponse): PracticeLocation {
  const emptyLocationValues = {
    address1: null,
    address2: null,
    country: null,
    state: null,
    postalCode: null,
    city: null,
  };

  const location = response.location ?? emptyLocationValues;
  return { ...location, practice: response.practice };
}
