import { StyleSheet, ImageBackground } from 'react-native';

import image from 'src/assets/images/standards-promo-image.png';
import { StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { fonts, palette } from 'src/styles';

import { ProPromoButton } from './ProPromoButton';

export const StandardsBanner = () => {
  return (
    <ImageBackground source={{ uri: image }} style={styles.image}>
      <StyledText style={styles.bannerText}>{i18n.t('standardsPromo:banner')}</StyledText>
      <ProPromoButton shadow />
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  image: {
    overflow: 'hidden',
    marginBottom: 16,
    borderRadius: 5,
    padding: 32,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  bannerText: {
    textTransform: 'uppercase',
    fontSize: 24,
    fontWeight: '700',
    fontFamily: fonts.din,
    color: palette.white,
    marginRight: 8,
    marginBottom: 16,
  },
});
