import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { StyledText, PrimaryButton, BaseCard } from 'src/components';
import { PLUMBS_SUPPORT_LINK, STANDARDS_SUPPORT_LINK } from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { typography } from 'src/styles';

interface Props {
  conversionId: string;
}

export const FrozenGroupMessage: React.FC<Props> = ({ conversionId }) => {
  const { primary } = useTheme();
  const { isPro } = useUserInfo();
  return (
    <BaseCard>
      <View style={styles.wrapper} testID="tile-frozen-group">
        <StyledText style={[typography.body4Bold, styles.header, { color: primary }]}>
          {i18n.t('groupConversion:frozenGroupTitle')}
        </StyledText>
        <Trans
          i18nKey="groupConversion:frozenGroupDescription"
          components={{
            p: <StyledText style={styles.description} />,
            contact: (
              <Link
                to={isPro ? STANDARDS_SUPPORT_LINK : PLUMBS_SUPPORT_LINK}
                external
                wrapper="text"
                style={{ color: primary }}
              />
            ),
          }}
        />
        <Link to={{ route: Route.GroupConversion, params: { id: conversionId } }}>
          <PrimaryButton
            title={i18n.t('groupConversion:activateSubscription')}
            size="medium"
            testID="btn-activate-subscription"
          />
        </Link>
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  header: {
    marginBottom: 10,
  },
  description: {
    maxWidth: 450,
    textAlign: 'center',
    marginBottom: 20,
  },
});
