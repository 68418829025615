import { Platform } from 'react-native';

import { EnvService } from 'src/services/EnvService';

declare global {
  interface Window {
    Cypress?: any;
    isProductionBuild?: boolean;
  }
}

const environment = EnvService.getEnv('ENVIRONMENT');

export const isDevelopmentBuild = process.env.NODE_ENV === 'development';
export const isProductionBuild = process.env.NODE_ENV === 'production';

export const isWeb = Platform.OS === 'web';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
// window.Cypress is defined for both E2E and Component test modes
export const isTest = (isWeb && !!window.Cypress) || environment === 'test' || environment === 'test-eng';
/** App is run by Cypress in Component Testing mode */
export const isComponentTesting =
  isWeb && !!window.Cypress && window.Cypress?.env('TEST_TYPE') === 'component';

const isAcademicDomain = isWeb && window.location.host.includes('academic');

export const isCurrentHostDifferentThanDefault = isWeb && isProductionBuild && isAcademicDomain;

export const isLocal = environment === 'dev';
export const isUAT = environment === 'uat';
export const isProduction = environment === 'prod';

export const isStandardsDomain = () => {
  if (!isWeb) return null;
  const getCurrentDomain = () => (typeof window !== 'undefined' ? window.location.origin : '');
  const currentDomain = getCurrentDomain();
  return currentDomain.toLowerCase().includes('standard') || currentDomain.includes('3005');
};

export const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
