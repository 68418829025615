import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingIndicator } from 'src/components';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';

import { fulfillSignIn } from '../state';
import { LoginFlowTemplate } from './LoginFlowTemplate';

interface Props {
  access?: string;
  refresh?: string;
}

export const TokenLogin: React.FC<Props> = ({ access, refresh }) => {
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    const decodeTokenAndLogin = async () => {
      if (access && refresh) {
        try {
          const decodedToken = jwtDecode<any>(access);
          if (decodedToken) {
            MixpanelService.track(MixpanelEvent.SignIn);
            dispatch(fulfillSignIn({ refresh, access }));
            navigation.navigate(Route.Home);
          }
        } catch (err) {
          setError(true);
        }
      }
    };

    const handleAutologin = async () => {
      try {
        await decodeTokenAndLogin();
      } catch (err) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    handleAutologin();
  }, [access, refresh, dispatch, navigation]);

  return (
    <LoginFlowTemplate headerTitle={isLoading ? '' : error ? i18n.t('authTokenLogin:error') : ''}>
      {isLoading ? <LoadingIndicator /> : null}
    </LoginFlowTemplate>
  );
};
