import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useIsSignedIn } from 'src/features/auth/hooks';
import { NavigationMenuItem } from 'src/navigation/types';
import { ifWeb } from 'src/styles';

import { MenuDesktopItem } from './MenuDesktopItem';
import { NavigationButtons } from '../NavigationButtons';

interface Props {
  items: NavigationMenuItem[];
}

export const MenuDesktop: React.FC<Props> = ({ items }) => {
  const isSignedIn = useIsSignedIn();

  return (
    <View style={styles.container}>
      <View style={styles.hidden}>
        {items.map((item, index) => (
          <MenuDesktopItem
            {...item}
            key={item.key}
            isLastItem={items.length - 1 === index}
            highlightOnHover={isSignedIn}
            testID={item.testID}
          />
        ))}
      </View>
      <NavigationButtons containerStyles={styles.buttonsWrapper} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
    ...ifWeb({
      position: 'static',
      zIndex: 'auto',
    }),
  },
  buttonsWrapper: {
    alignSelf: 'center',
  },
  hidden: {
    display: 'none',
  },
});
