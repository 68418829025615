import * as R from 'ramda';

import { ApiError } from 'src/constants/types';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import type { TokenPayloadUserInfo } from 'src/features/auth/types';

import {
  BASIC_PLAN_MAX_LICENCE_QUANTITY,
  PRO_PLAN_MAX_LICENCE_QUANTITY,
  REGISTER_ROLES_CONFIG,
  ROLES_CONFIG,
  STUDENT_ROLES,
} from './constants';
import {
  BasePlanSetup,
  BillingPeriodDependentPlan,
  CardType,
  isBillingPeriodDependentPlan,
  isQuantityDependentPlan,
  PlanConfig,
  PlanSetup,
  PromotionSetup,
  QuantityDependentPlan,
  RoleConfig,
  SingleLicensePlan,
  BillingPeriod,
  Plan,
  RecurlyPlan,
  Role,
  GetBillingInformationResponse,
  BillingInformation,
  RecurlyStudentPlan,
  StudentRole,
  SubscriptionStatusInfo,
  SubscriptionStatus,
  PlanDetails,
  RegistrationPlans,
  RegisterRoleConfig,
  RegisterPlanSetup,
  RegisterBasePlanSetup,
} from './types';

export function getRecurlyPlan(
  role: Role,
  quantity: number,
  plan: Plan = Plan.NORMAL,
  billingPeriod: BillingPeriod = BillingPeriod.ANNUAL,
): RecurlyPlan {
  const roleConfig: RoleConfig = ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];

  if (!planConfig) {
    throw Error(`Cannot find plan setup for ${role} role and ${plan} plan`);
  }

  if (isQuantityDependentPlan(planConfig)) {
    planConfig = quantity > 1 ? planConfig.multiple : planConfig.single;
  } else if (quantity > 1) {
    // there's no plan with only multiple licences option, so if plan is not 'quantity dependand', it means that's only for single subscribers
    throw Error(`Cannot find plan setup for ${role} role, ${plan} plan and multiple licences`);
  }

  if (!Object.values(BillingPeriod).includes(billingPeriod)) {
    throw Error(`Cannot find plan setup for ${billingPeriod} billing period`);
  }

  if (isBillingPeriodDependentPlan(planConfig)) {
    return planConfig[billingPeriod];
  } else if (billingPeriod === BillingPeriod.MONTHLY) {
    // there's no plan with only monthly billing period, so if plan is not 'billing period dependent', it means that's only annual
    throw Error(`Cannot find plan setup for ${role} role, ${plan} and monthly billing period`);
  }

  return planConfig;
}

export function getRegisterRecurlyPlan(
  role: Role,
  quantity: number,
  plan: RegistrationPlans = RegistrationPlans.NORMAL,
  billingPeriod: BillingPeriod = BillingPeriod.ANNUAL,
): RecurlyPlan {
  const roleConfig: RegisterRoleConfig = REGISTER_ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];

  if (!planConfig) {
    throw Error(`Cannot find plan setup for ${role} role and ${plan} plan`);
  }

  if (isQuantityDependentPlan(planConfig)) {
    planConfig = quantity > 1 ? planConfig.multiple : planConfig.single;
  } else if (quantity > 1) {
    // there's no plan with only multiple licences option, so if plan is not 'quantity dependand', it means that's only for single subscribers
    throw Error(`Cannot find plan setup for ${role} role, ${plan} plan and multiple licences`);
  }

  if (!Object.values(BillingPeriod).includes(billingPeriod)) {
    throw Error(`Cannot find plan setup for ${billingPeriod} billing period`);
  }

  if (isBillingPeriodDependentPlan(planConfig)) {
    return planConfig[billingPeriod];
  } else if (billingPeriod === BillingPeriod.MONTHLY) {
    // there's no plan with only monthly billing period, so if plan is not 'billing period dependent', it means that's only annual
    throw Error(`Cannot find plan setup for ${role} role, ${plan} and monthly billing period`);
  }

  return planConfig;
}

export function getPlanDetailsFromPlanSetup(planSetup: PlanSetup): PlanDetails {
  return {
    planCode: getRecurlyPlan(planSetup.role, planSetup.quantity, planSetup.plan, planSetup.billingPeriod),
    quantity: planSetup.quantity,
    discountCode: planSetup.discountCode,
  };
}

export function getRegisterPlanDetailsFromPlanSetup(planSetup: RegisterPlanSetup): PlanDetails {
  return {
    planCode: getRegisterRecurlyPlan(
      planSetup.role,
      planSetup.quantity,
      planSetup.plan,
      planSetup.billingPeriod,
    ),
    quantity: planSetup.quantity,
    discountCode: planSetup.discountCode,
  };
}
type MaybeBillingPeriod = BillingPeriod | null;

function findBillingPeriodInQuantityDependentPlan(
  recurlyPlan: RecurlyPlan,
  planConfig: QuantityDependentPlan,
): MaybeBillingPeriod {
  for (const plan of Object.values(planConfig)) {
    if (isBillingPeriodDependentPlan(plan)) {
      const billingPeriod = findBillingPeriodInPlanConfig(recurlyPlan, plan);
      if (billingPeriod) return billingPeriod;
    }
    if (Object.values(planConfig).includes(recurlyPlan)) {
      return BillingPeriod.ANNUAL;
    }
  }

  return null;
}

function findBillingPeriodInBillingPeriodDependentPlan(
  recurlyPlan: RecurlyPlan,
  planConfig: BillingPeriodDependentPlan,
): MaybeBillingPeriod {
  for (const [billingPeriod, billingPeriodDependentPlan] of Object.entries(planConfig) as [
    BillingPeriod,
    RecurlyPlan,
  ][]) {
    if (billingPeriodDependentPlan === recurlyPlan) {
      return billingPeriod;
    }
  }
  return null;
}

function findBillingPeriodInSingleLicensePlan(
  recurlyPlan: RecurlyPlan,
  planConfig: SingleLicensePlan,
): MaybeBillingPeriod {
  return planConfig === recurlyPlan ? BillingPeriod.ANNUAL : null;
}

function findBillingPeriodInPlanConfig(
  recurlyPlan: RecurlyPlan,
  planConfig: PlanConfig,
): MaybeBillingPeriod {
  if (isQuantityDependentPlan(planConfig)) {
    return findBillingPeriodInQuantityDependentPlan(recurlyPlan, planConfig);
  }
  if (isBillingPeriodDependentPlan(planConfig)) {
    return findBillingPeriodInBillingPeriodDependentPlan(recurlyPlan, planConfig);
  }
  return findBillingPeriodInSingleLicensePlan(recurlyPlan, planConfig);
}

function findRegisterBillingPeriodInPlanConfig(
  recurlyPlan: RecurlyPlan,
  planConfig: PlanConfig,
): MaybeBillingPeriod {
  if (isQuantityDependentPlan(planConfig)) {
    return findBillingPeriodInQuantityDependentPlan(recurlyPlan, planConfig);
  }
  if (isBillingPeriodDependentPlan(planConfig)) {
    return findBillingPeriodInBillingPeriodDependentPlan(recurlyPlan, planConfig);
  }
  return findBillingPeriodInSingleLicensePlan(recurlyPlan, planConfig);
}

type MaybePlanAndBillingPeriod = { plan: Plan; billingPeriod: BillingPeriod } | null;

type RegisterMaybePlanAndBillingPeriod = { plan: RegistrationPlans; billingPeriod: BillingPeriod } | null;

function findPlanAndBillingPeriodInRole(
  recurlyPlan: RecurlyPlan,
  roleConfig: RoleConfig,
): MaybePlanAndBillingPeriod {
  for (const [plan, planConfig] of Object.entries(roleConfig) as [Plan, PlanConfig][]) {
    const billingPeriod: MaybeBillingPeriod = findBillingPeriodInPlanConfig(recurlyPlan, planConfig);
    if (billingPeriod) {
      return {
        plan,
        billingPeriod,
      };
    }
  }
  return null;
}

function findRegisterPlanAndBillingPeriodInRole(
  recurlyPlan: RecurlyPlan,
  roleConfig: RegisterRoleConfig,
): RegisterMaybePlanAndBillingPeriod {
  for (const [plan, planConfig] of Object.entries(roleConfig) as [RegistrationPlans, PlanConfig][]) {
    const billingPeriod: MaybeBillingPeriod = findRegisterBillingPeriodInPlanConfig(
      recurlyPlan,
      planConfig,
    );
    if (billingPeriod) {
      return {
        plan,
        billingPeriod,
      };
    }
  }
  return null;
}

export function getPlanByRole(role: Role, defaultPlan: Plan): Plan {
  const roleConfig = ROLES_CONFIG[role];
  if (roleConfig[defaultPlan]) return defaultPlan;

  if (roleConfig[Plan.NORMAL]) return Plan.NORMAL;
  return Plan.PRO;
}

export function getRegisterPlanByRole(role: Role, defaultPlan: RegistrationPlans): RegistrationPlans {
  const roleConfig = REGISTER_ROLES_CONFIG[role];
  if (roleConfig[defaultPlan]) return defaultPlan;

  if (roleConfig[RegistrationPlans.NORMAL]) return RegistrationPlans.NORMAL;
  return RegistrationPlans.STANDARDS;
}

export function getBasePlanSetup(recurlyPlan: RecurlyPlan): BasePlanSetup {
  for (const [role, roleConfig] of Object.entries(ROLES_CONFIG) as [Role, RoleConfig][]) {
    const planAndBillingPeriod: MaybePlanAndBillingPeriod = findPlanAndBillingPeriodInRole(
      recurlyPlan,
      roleConfig,
    );
    if (planAndBillingPeriod) {
      return {
        role,
        ...planAndBillingPeriod,
      };
    }
  }
  throw Error(`Cannot find plan setup for plan ${recurlyPlan}`);
}
export function getRegisterBasePlanSetup(recurlyPlan: RecurlyPlan): RegisterBasePlanSetup {
  for (const [role, roleConfig] of Object.entries(REGISTER_ROLES_CONFIG) as [Role, RegisterRoleConfig][]) {
    const planAndBillingPeriod: RegisterMaybePlanAndBillingPeriod = findRegisterPlanAndBillingPeriodInRole(
      recurlyPlan,
      roleConfig,
    );
    if (planAndBillingPeriod) {
      return {
        role,
        ...planAndBillingPeriod,
      };
    }
  }
  throw Error(`Cannot find plan setup for plan ${recurlyPlan}`);
}

export function getPlanSetupFromPlanDetails(planDetails: PlanDetails): PlanSetup {
  return {
    ...getBasePlanSetup(planDetails.planCode),
    quantity: planDetails.quantity,
    discountCode: planDetails.discountCode || '',
  };
}

export function getRegisterPlanSetupFromPlanDetails(planDetails: PlanDetails): RegisterPlanSetup {
  return {
    ...getRegisterBasePlanSetup(planDetails.planCode),
    quantity: planDetails.quantity,
    discountCode: planDetails.discountCode || '',
  };
}

export const isStudentRole = (role: Role): role is StudentRole =>
  [Role.VETERINARY_STUDENT, Role.PHARMACY_STUDENT].includes(role);

export const isStudentRecurlyPlan = (recurlyPlan: RecurlyPlan) =>
  [RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL, RecurlyPlan.PHARMACY_BASIC_STUDENT].includes(recurlyPlan);

export const isProRecurlyPlan = (recurlyPlan: RecurlyPlan): boolean => {
  const { plan } = getBasePlanSetup(recurlyPlan);
  return plan === Plan.PRO;
};

export const getRecurlyStudentPlan = (role: StudentRole): RecurlyStudentPlan => {
  return role === Role.PHARMACY_STUDENT
    ? RecurlyPlan.PHARMACY_BASIC_STUDENT
    : RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL;
};

export const getCvvPlaceholder = (cardType: CardType): string =>
  cardType === CardType.AmericanExpress ? '••••' : '•••';

export const getCreditCardNumberPlaceholder = (lastFour: string, cardType?: CardType): string => {
  switch (cardType) {
    case CardType.AmericanExpress:
      return `•••• •••••• •${lastFour}`;
    case CardType.DinersClub:
      return `•••• •••••• ${lastFour}`;
    default:
      return `•••• •••• •••• ${lastFour}`;
  }
};

export const arePlanParamsValid = (data: PlanSetup) => {
  const { billingPeriod, role, quantity, plan } = data;

  try {
    const recurlyPlan = getRecurlyPlan(role, quantity, plan, billingPeriod);
    if (!recurlyPlan) return false;
    if (quantity > getPlanMaxLicenseQuantity(plan)) return false;
  } catch (err) {
    return false;
  }
  return true;
};

export const hasRoleNormalAndProVariants = (role: Role): boolean =>
  R.allPass([R.has(Plan.NORMAL), R.has(Plan.PRO)])(ROLES_CONFIG[role]);

export const hasPlanSetupMonthlyAndAnnualPaymentOptions = (
  role: Role,
  plan: Plan,
  quantity: number,
): boolean => {
  const roleConfig = ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];
  if (!planConfig) return false;
  if (isQuantityDependentPlan(planConfig)) {
    planConfig = planConfig[quantity === 1 ? 'single' : 'multiple'];
  }
  return !!planConfig && R.allPass([R.has(BillingPeriod.ANNUAL), R.has(BillingPeriod.MONTHLY)])(planConfig);
};
export const hasRegisterPlanSetupMonthlyAndAnnualPaymentOptions = (
  role: Role,
  plan: RegistrationPlans,
  quantity: number,
): boolean => {
  const roleConfig = REGISTER_ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];
  if (!planConfig) return false;
  if (isQuantityDependentPlan(planConfig)) {
    planConfig = planConfig[quantity === 1 ? 'single' : 'multiple'];
  }
  return !!planConfig && R.allPass([R.has(BillingPeriod.ANNUAL), R.has(BillingPeriod.MONTHLY)])(planConfig);
};

export const isRoleAStudentRole = (role: Role): boolean => STUDENT_ROLES.includes(role);

export const getPlanMaxLicenseQuantity = (plan: Plan): number =>
  plan === Plan.NORMAL ? BASIC_PLAN_MAX_LICENCE_QUANTITY : PRO_PLAN_MAX_LICENCE_QUANTITY;

export const getRegisterPlanMaxLicenseQuantity = (plan: RegistrationPlans): number =>
  plan === RegistrationPlans.NORMAL ? BASIC_PLAN_MAX_LICENCE_QUANTITY : PRO_PLAN_MAX_LICENCE_QUANTITY;

export const doesRoleHaveProVariant = (role: Role) => !!ROLES_CONFIG[role].pro;

export const promotionAllowsPlanSelection = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice' | 'basicPrice'>,
) => doesPromotionHaveBasicPlan(promotion) && doesPromotionHaveProPlan(promotion);

export const doesPromotionHaveProPlan = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice'>,
) => promotion.proAnnualPrice !== null || promotion.proMonthlyPrice !== null;

export const doesPromotionHaveBasicPlan = (promotion: Pick<PromotionSetup, 'basicPrice'>) =>
  promotion.basicPrice !== null;

export const doesProPromotionAllowDifferentBillingPeriods = (promotion: PromotionSetup) =>
  promotion.proAnnualPrice !== null && promotion.proMonthlyPrice !== null;

export const doesPromotionContainPlanForBillingPeriod = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice' | 'basicPrice'>,
  plan: Plan,
  billingPeriod: BillingPeriod,
) => {
  if (plan === Plan.PRO) {
    if (billingPeriod === BillingPeriod.MONTHLY) {
      return promotion.proMonthlyPrice !== null;
    } else if (billingPeriod === BillingPeriod.ANNUAL) {
      return promotion.proAnnualPrice !== null;
    }
  } else if (billingPeriod === BillingPeriod.ANNUAL && plan === Plan.NORMAL) {
    return promotion.basicPrice !== null;
  }
  throw new Error(
    `Provided Billing Period (${billingPeriod}) and Plan (${plan}) values combination couldn't be recognized`,
  );
};

export const doesRegisterPromotionContainPlanForBillingPeriod = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice' | 'basicPrice'>,
  plan: RegistrationPlans,
  billingPeriod: BillingPeriod,
) => {
  if (plan === RegistrationPlans.STANDARDS) {
    if (billingPeriod === BillingPeriod.MONTHLY) {
      return promotion.proMonthlyPrice !== null;
    } else if (billingPeriod === BillingPeriod.ANNUAL) {
      return promotion.proAnnualPrice !== null;
    }
  } else if (billingPeriod === BillingPeriod.ANNUAL && plan === RegistrationPlans.NORMAL) {
    return promotion.basicPrice !== null;
  }
  throw new Error(
    `Provided Billing Period (${billingPeriod}) and Plan (${plan}) values combination couldn't be recognized`,
  );
};

/**
 * @throws Throws error if promotion doesn't include provided Plan
 */
export const getInitialPromoBillingPeriod = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice' | 'basicPrice'>,
  plan: Plan,
  currentBillingPeriod?: BillingPeriod,
) => {
  if (
    (plan === Plan.NORMAL && !doesPromotionHaveBasicPlan(promotion)) ||
    (plan === Plan.PRO && !doesPromotionHaveProPlan(promotion))
  ) {
    throw new Error(`Provided promotion doesn't include ${plan} plan`);
  }

  if (plan === Plan.NORMAL) {
    return BillingPeriod.ANNUAL;
  }

  if (
    currentBillingPeriod &&
    doesPromotionContainPlanForBillingPeriod(promotion, plan, currentBillingPeriod)
  ) {
    return currentBillingPeriod;
  }

  if (plan === Plan.PRO) {
    return promotion.proAnnualPrice !== null ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY;
  }

  return BillingPeriod.ANNUAL;
};

/**
 * @throws Throws error if promotion doesn't include provided Plan
 */
export const getRegisterInitialPromoBillingPeriod = (
  promotion: Pick<PromotionSetup, 'proAnnualPrice' | 'proMonthlyPrice' | 'basicPrice'>,
  plan: RegistrationPlans,
  currentBillingPeriod?: BillingPeriod,
) => {
  if (
    (plan === RegistrationPlans.NORMAL && !doesPromotionHaveBasicPlan(promotion)) ||
    (plan === RegistrationPlans.STANDARDS && !doesPromotionHaveProPlan(promotion))
  ) {
    throw new Error(`Provided promotion doesn't include ${plan} plan`);
  }

  if (plan === RegistrationPlans.NORMAL) {
    return BillingPeriod.ANNUAL;
  }

  if (
    currentBillingPeriod &&
    doesRegisterPromotionContainPlanForBillingPeriod(promotion, plan, currentBillingPeriod)
  ) {
    return currentBillingPeriod;
  }

  if (plan === RegistrationPlans.STANDARDS) {
    return promotion.proAnnualPrice !== null ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY;
  }

  return BillingPeriod.ANNUAL;
};

export const getPlanDetailsFromPromotion = (
  promotion: PromotionSetup,
  predefinedData: Partial<PlanSetup> = {},
): PlanSetup => {
  const hasProPlan = doesPromotionHaveProPlan(promotion);
  const plan = predefinedData.plan ?? (hasProPlan ? Plan.PRO : Plan.NORMAL);
  const billingPeriod = getInitialPromoBillingPeriod(promotion, plan, predefinedData.billingPeriod);

  return {
    role: Role.VETERINARY,
    quantity: 1,
    discountCode: '',
    ...predefinedData,
    billingPeriod,
    plan,
  };
};

export const getRegisterPlanDetailsFromPromotion = (
  promotion: PromotionSetup,
  predefinedData: Partial<RegisterPlanSetup> = {},
): RegisterPlanSetup => {
  const hasProPlan = doesPromotionHaveProPlan(promotion);
  const plan = predefinedData.plan ?? (hasProPlan ? RegistrationPlans.STANDARDS : RegistrationPlans.NORMAL);
  const billingPeriod = getRegisterInitialPromoBillingPeriod(promotion, plan, predefinedData.billingPeriod);

  return {
    role: Role.VETERINARY,
    quantity: 1,
    discountCode: '',
    ...predefinedData,
    billingPeriod,
    plan,
  };
};

export const isBillingInformation = (
  response: GetBillingInformationResponse,
): response is BillingInformation => {
  return !!response.billingAddress;
};

export const isExpiredManagedGroup = (
  data: Pick<TokenPayloadUserInfo, 'groupInfo' | 'expiredSubscription'>,
) => data.groupInfo?.removed === false && data.groupInfo.isManagedGroup && data.expiredSubscription;

export const getErrorMessagesForSubscriptionForm = (errors: any) => {
  const {
    firstName,
    lastName,
    email,
    password,
    discountCode,
    quantity,
    address,
    demographics: demographicsRaw,
    ...other
  } = errors;

  const getErrors = R.pipe<
    Record<string, ApiError | ApiError[]>,
    Record<string, ApiError | ApiError[]>,
    Record<string, string>
  >(R.reject(R.anyPass([R.isEmpty, R.isNil])), R.mapObjIndexed(getErrorMessageForApiError));

  const { practice, ...demographics } = demographicsRaw || {};

  const addressErrors = getErrors(address || {});
  const demographicsErrors = getErrors(demographics || {});
  const accountErrors = getErrors({ email, password });
  const planErrors = getErrors({ discountCode, quantity });
  const profileErrors = { ...getErrors({ firstName, lastName, practice }), ...addressErrors };
  const otherErrors = getErrors(other);

  return {
    accountErrors,
    profileErrors,
    demographicsErrors,
    planErrors,
    otherErrors,
  };
};

export const isPromotionForNewSubscribersOnlyError = (error: any) =>
  error?.response?.data?.detail?.code === 'new_subscribers_only';

export const isPromotionForExistingSubscribersOnlyError = (error: any) =>
  error?.response?.data?.detail?.code === 'existing_subscribers_only';

interface CanUpgradeToProParams {
  isPro: boolean;
  inTrial: boolean;
  role: Role;
  numberOfLicences: number;
  appliedPromotion?: PromotionSetup | null;
}

export const canUpgradeToPro = (params: CanUpgradeToProParams) => {
  const baseValue =
    !params.isPro &&
    !isStudentRole(params.role) &&
    !params.inTrial &&
    doesRoleHaveProVariant(params.role) &&
    (!params.appliedPromotion || doesPromotionHaveProPlan(params.appliedPromotion));

  return {
    now: baseValue && params.numberOfLicences <= PRO_PLAN_MAX_LICENCE_QUANTITY,
    afterLicencesReduction: baseValue && params.numberOfLicences > PRO_PLAN_MAX_LICENCE_QUANTITY,
  };
};

export const parseSubscriptionDataFromBackend = (data: SubscriptionStatusInfo) => {
  const isCancelled = data.state === SubscriptionStatus.CANCELED;
  const isActive = data.state === SubscriptionStatus.ACTIVE;
  const isExpired = data.state === SubscriptionStatus.EXPIRED;
  const planSetup = getBasePlanSetup(data.planCode);
  const isPro = planSetup.plan === Plan.PRO;
  const isStudent = isStudentRecurlyPlan(data.planCode);

  const canManageLicencesNumber = !isStudent && !data.inTrial;
  const canUserUpgradeToPro =
    data &&
    planSetup &&
    canUpgradeToPro({
      inTrial: !!data.inTrial,
      isPro,
      appliedPromotion: data.promotion,
      numberOfLicences: data.numberOfLicences,
      role: planSetup.role,
    });

  const canAddMoreLicences =
    canManageLicencesNumber &&
    data.numberOfLicences < getPlanMaxLicenseQuantity(planSetup.plan) &&
    !data.promotion;

  return {
    ...data,
    isCancelled,
    isActive,
    isExpired,
    planSetup,
    canManageLicencesNumber,
    canUserUpgradeToPro,
    canAddMoreLicences,
  };
};
