import React from 'react';

import { useIsSignedIn } from 'src/features/auth/hooks';

import { AccountExpirationNotification } from './AccountExpirationNotification';
// import { CSATModal } from './CSATModal';
import { GroupInvitationNotificationModal } from './GroupInvitationNotification';
// import { NPSModal } from './NPSModal';
import { StudentNoInstantSuccessNotification } from './StudentNoInstantSuccessNotification';
import { StudentRenewalSuccessNotification } from './StudentRenewalSuccessNotification';
import { useUserNotificationsList } from '../hooks';

export const UserNotifications: React.FC = () => {
  const { data } = useUserNotificationsList();
  const isSignedIn = useIsSignedIn();

  return (
    <>
      {isSignedIn &&
        data?.notifications.map((item) => {
          switch (item.type) {
            case 'group-invitation':
              return <GroupInvitationNotificationModal data={item} key={item.id} />;
            // TODO: temporary disabled - https://briefmedia.atlassian.net/browse/VET-1869
            // case 'customer-satisfaction-survey':
            //   return <CSATModal data={item} key={item.id} />;
            // case 'net-promoter-score':
            //   return <NPSModal data={item} key={item.id} />;
            case 'expiring-account':
              return <AccountExpirationNotification data={item} key={item.id} />;
            case 'student-renewal-info-needed':
              return <StudentNoInstantSuccessNotification data={item} key={item.id} />;
            case 'student-renewal-confirm':
              return <StudentRenewalSuccessNotification data={item} key={item.id} />;
            default:
              return null;
          }
        })}
    </>
  );
};
