import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { showNotification, isWeb } from 'src/helpers';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { AppDispatch } from 'src/state/store';

import {
  autologin,
  ipLogin,
  sendResetPasswordEmail,
  setNewPassword,
  signIn,
  validatePasswordRecoveryToken,
} from '../api';
import { TokenValidationError } from '../enums';
import { loginRedirectUrl } from '../helpers';
import { SavedCredentialsService } from '../services/SavedCredentialsService';
import { fulfillIPLogin, fulfillSignIn, openInactiveSharedAccountWarning } from '../state';
import type { IPLoginResponse, IPLoginErrorResponse } from '../types';

export const useSignIn = () => {
  const dispatch = useDispatch<AppDispatch>();

  const logUserIn = (responseData: any, inputData: any) => {
    MixpanelService.track(MixpanelEvent.SignIn);
    dispatch(fulfillSignIn(responseData));

    if (inputData.remember) {
      SavedCredentialsService.save(inputData);
    } else {
      SavedCredentialsService.reset();
    }
  };

  return useMutation({
    mutationKey: ['sign-in'],
    mutationFn: signIn,
    onSuccess: (responseData, inputData) => {
      const { access, refresh } = responseData;
      const decodedToken = jwtDecode<any>(access);

      const isPro = decodedToken.is_pro;

      const expiredSubscription = decodedToken.expired_subscription;

      // If the user has access to the standards UX, handle redirects or login accordingly
      if (isWeb && !expiredSubscription) {
        const shouldRedirectTo = loginRedirectUrl(isPro);

        if (shouldRedirectTo) {
          const redirectUrl = `${shouldRedirectTo}?access=${access}&refresh=${refresh}`;
          window.location.replace(redirectUrl);
          return;
        }
      }

      // Log the user in for all other cases
      logUserIn(responseData, inputData);
    },
    onError: (error) => {
      if (error.response?.data.expired) {
        dispatch(openInactiveSharedAccountWarning());
      }
    },
  });
};

export const usePasswordResetEmail = () => {
  return useMutation({ mutationFn: sendResetPasswordEmail });
};

export const usePasswordRecoveryTokenValidation = (token?: string, userId?: string) => {
  return useQuery<boolean | undefined, TokenValidationError>({
    queryKey: ['password-recovery-token', token, userId],
    queryFn: async () => {
      try {
        await validatePasswordRecoveryToken(token!, userId!);
        return true;
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response) {
          const { status } = error.response;
          if (status === 404) {
            throw TokenValidationError.NotFound;
          } else if (status === 400) {
            throw TokenValidationError.Expired;
          }
        }
        throw TokenValidationError.Default;
      }
    },
    enabled: !!token && !!userId,
  });
};

export const useSetNewPassword = () => {
  return useMutation({ mutationFn: setNewPassword });
};

export const useIPLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();

  const loginMutation = useMutation<AxiosResponse<IPLoginResponse>, AxiosError<IPLoginErrorResponse>>({
    mutationFn: ipLogin,

    onSuccess: ({ data }) => {
      dispatch(fulfillIPLogin(data));
    },
    onError: (error) => {
      if (error.response?.data.expired) {
        dispatch(openInactiveSharedAccountWarning());
        navigation.navigate(Route.SignIn, {});
      }
    },
  });

  const errorData = useMemo(() => {
    if (loginMutation.error) {
      return loginMutation.error?.response?.data;
    }
  }, [loginMutation.error]);

  return { ...loginMutation, errorData };
};

export const useAutologin = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationKey: ['autologin'],
    mutationFn: autologin,
    onSuccess: (data) => {
      dispatch(fulfillSignIn(data));
    },
    onError: (error) => {
      const { nonFieldErrors } = getMessagesFromErrorResponse<{ nonFieldErrors?: string }>(error);
      showNotification({ type: 'error', title: nonFieldErrors });
    },
  });
};
