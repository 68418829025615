import React from 'react';

import { PRICING_LINK } from 'src/constants/externalLinks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { NavigationMenuItem } from 'src/navigation/types';

import { FeaturesExtendedDropdown } from './ExtendedDropdown/FeaturesExtendedDropdown';
import { SolutionsExtendedDropdown } from './ExtendedDropdown/SolutionsExtendedDropdown';
import { WhyPlumbsExtendedDropdown } from './ExtendedDropdown/WhyPlumbsExtendedDropdown';
import { NavigationDesktop } from './NavigationDesktop/NavigationDesktop';
import { NavigationMobile } from './NavigationMobile/NavigationMobile';

export const NavigationPanel: React.FC = () => {
  const { isDesktop } = useDeviceInfo();
  const menuItems: NavigationMenuItem[] = [
    {
      text: i18n.t('navigation:solutions'),
      key: 'solutions',
      ExtendedDropdownComponent: SolutionsExtendedDropdown,
    },
    {
      text: i18n.t('navigation:features'),
      key: 'features',
      ExtendedDropdownComponent: FeaturesExtendedDropdown,
    },
    {
      text: i18n.t('navigation:whyPlumbs'),
      key: 'how',
      ExtendedDropdownComponent: WhyPlumbsExtendedDropdown,
    },
    {
      text: i18n.t('navigation:pricingAndPlans'),
      key: 'pricing',
      to: PRICING_LINK,
      external: true,
    },
  ];

  return isDesktop ? <NavigationDesktop items={menuItems} /> : <NavigationMobile items={menuItems} />;
};
