import dayjs from 'dayjs';
import * as R from 'ramda';
import { useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { PrimaryButton, InputItem, Select, StyledText, Subheader } from 'src/components';
import { STUDENT_PLAN_PRICE } from 'src/constants/constants';
import { PLUMBS_SUPPORT_LINK, STANDARDS_SUPPORT_LINK } from 'src/constants/externalLinks';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { usePersonalInformation } from 'src/features/profile';
import { SubscriptionSection } from 'src/features/subscription/components/forms/SubscriptionSections';
import { getBasePlanSetup } from 'src/features/subscription/helpers';
import { Plan } from 'src/features/subscription/types';
import { showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { i18n } from 'src/locale';
import { typography } from 'src/styles';

import { DateInput, dateInputInitialValues, dateInputValidators } from './DateInput';
import { StudentUniversitySelect } from './StudentUniversitySelect';
import { useStudentVerificationContext } from '../context/StudentVerificationContext';
import { useVerifyStudentStatus } from '../hooks/queries';
import { useStudentForm } from '../hooks/useStudentForm';
import {
  StudentVerificationErrorMessages,
  StudentVerificationFormParams,
  StudentVerificationParams,
} from '../types';

interface Props {
  email: string;
}

const validationSchema: Yup.SchemaOf<StudentVerificationFormParams> = Yup.object().shape({
  email: Yup.string().email(i18n.t('validation:enterValidEmail')).required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  country: Yup.string().required(),
  studentEmail: Yup.string().email(i18n.t('validation:enterValidEmail')).required(),
  organizationId: Yup.number().required(),
  organizationName: Yup.string().required(),
  ...dateInputValidators,
});

export const StudentDataForm: React.FC<Props> = ({ email: subscriptionEmail = '' }) => {
  const { onStudentDataSubmit, plan } = useStudentVerificationContext();

  const { mutate: verifyStudentStatus, isPending } = useVerifyStudentStatus();

  const onSubmit = (values: StudentVerificationFormParams) => {
    resetForm({ values }); // To reset 'dirty' value

    const birthDate = dayjs({
      dates: values.birthDate || undefined,
      months: values.birthMonth || undefined,
      years: values.birthYear || undefined,
    }).format('YYYY-MM-DD');

    verifyData({
      ...values,
      birthDate,
      planCode: plan!,
    });
  };

  const verifyData = async (values: StudentVerificationParams) => {
    verifyStudentStatus(values, {
      onSuccess: (data) => {
        const { verificationId, status, loginUrl } = data;
        onStudentDataSubmit({
          data: values,
          status,
          verificationId,
          ssoLoginUrl: loginUrl,
        });

        if (status === 'failed') {
          // SheerID returned some errors
          showNotification({
            type: 'error',
            title: i18n.t('subscriptionProcess:studentVerificationError', {
              supportLink: isPro ? STANDARDS_SUPPORT_LINK : PLUMBS_SUPPORT_LINK,
            }),
            autoHide: false,
          });
        }
      },
      onError: (err: any) => {
        const { detail, ...rest } = getMessagesFromErrorResponse<StudentVerificationErrorMessages>(err);
        if (!R.isEmpty(rest)) {
          setErrors(rest);
          setTouched(
            R.mapObjIndexed(() => true, rest),
            false,
          );
        } else {
          showNotification({ type: 'error', title: detail, autoHide: false });
        }
      },
    });
  };

  const { data: personalInformation } = usePersonalInformation();

  /*
   * NOTE: `errors.birthDate` contains backend errors for the whole
   * `birthDate`
   *
   */
  const studentSubscriptionFormInitialValues = {
    country: '',
    email: subscriptionEmail,
    studentEmail: subscriptionEmail,
    firstName: '',
    lastName: '',
    organizationId: 1,
    organizationName: '',
    ...dateInputInitialValues,
  };

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    setErrors,
    dirty,
    resetForm,
    setTouched,
    countriesOptions,
    handleOrganizationChange,
  } = useStudentForm<StudentVerificationFormParams>(
    studentSubscriptionFormInitialValues,
    plan!,
    validationSchema,
    onSubmit,
  );

  const clearSelectedOrganization = useCallback(() => {
    setFieldValue('organizationId', 0);
    setFieldValue('organizationName', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // resetting country value when user switches between the plans as there may be different list of countries available
    setFieldValue('country', '');
    clearSelectedOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (personalInformation) {
      setFieldValue('firstName', personalInformation.firstName);
      setFieldValue('lastName', personalInformation.lastName);
    }
  }, [personalInformation, setFieldValue]);

  useEffect(() => {
    clearSelectedOrganization();
  }, [values.country, clearSelectedOrganization]);

  const isPro = !!plan && getBasePlanSetup(plan).plan === Plan.PRO;

  return (
    <>
      <Subheader
        title={i18n.t(
          isPro
            ? 'subscriptionProcess:studentProDisclaimerHeader'
            : 'subscriptionProcess:studentDisclaimerHeader',
          {
            value: STUDENT_PLAN_PRICE,
          },
        )}
      ></Subheader>
      <StyledText style={[typography.body2, styles.description]}>
        {i18n.t('subscriptionProcess:studentDisclaimerContent')}
      </StyledText>
      <SubscriptionSection.Form testID="student-form">
        <SubscriptionSection.Row>
          <SubscriptionSection.Item>
            <InputItem
              label={i18n.t('user:firstNameLabel')}
              value={values.firstName || ''}
              onChangeText={handleChange('firstName')!}
              error={errors.firstName}
              touched={touched.firstName}
              testID="first-name-input"
            />
          </SubscriptionSection.Item>
          <SubscriptionSection.Item>
            <InputItem
              label={i18n.t('user:lastNameLabel')}
              value={values.lastName || ''}
              onChangeText={handleChange('lastName')!}
              error={errors.lastName}
              touched={touched.lastName}
              testID="last-name-input"
            />
          </SubscriptionSection.Item>
          <SubscriptionSection.Item>
            <InputItem
              inputMode="email"
              label={i18n.t('subscriptionProcess:emailLabel')}
              value={values.studentEmail || ''}
              onChangeText={handleChange('studentEmail')!}
              description={i18n.t('subscriptionProcess:studentEmailDescription')}
              error={errors.studentEmail}
              touched={touched.studentEmail}
              testID="student-email-input"
            />
          </SubscriptionSection.Item>
        </SubscriptionSection.Row>
        <SubscriptionSection.Row>
          <SubscriptionSection.Item>
            <DateInput
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errors={errors}
            />
          </SubscriptionSection.Item>
          <SubscriptionSection.Item>
            <Select
              options={countriesOptions}
              value={values.country}
              onChange={({ value }) => handleChange('country')!(value)}
              label={i18n.t('user:countryLabel')}
              error={errors.country}
              touched={touched.country}
              searchType="internal"
              testID="country-select"
            />
          </SubscriptionSection.Item>
          <SubscriptionSection.Item>
            {!!values.country && (
              <StudentUniversitySelect
                organizationId={values.organizationId}
                organizationName={values.organizationName}
                onChange={handleOrganizationChange}
                error={errors.organizationName}
                touched={touched.organizationName}
                country={values.country}
                plan={plan!}
              />
            )}
          </SubscriptionSection.Item>
        </SubscriptionSection.Row>
      </SubscriptionSection.Form>
      <SubscriptionSection.ButtonsWrapper>
        <PrimaryButton
          title={i18n.t('subscriptionProcess:verifyMyStudentStatus')}
          onPress={handleSubmit}
          loading={isPending}
          testID="verify-student-btn"
          disabled={!dirty}
        />
      </SubscriptionSection.ButtonsWrapper>
    </>
  );
};

const styles = StyleSheet.create({
  description: {
    marginBottom: 20,
  },
});
