import React from 'react';
import { StyleSheet, View, ViewStyle, StyleProp, TextStyle } from 'react-native';

import { GradientBar } from 'src/components';
import { BaseButton as BaseButtonOld } from 'src/components/Buttons/BaseButton.old';
import { IconName } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { isStandardsDomain } from 'src/helpers';
import { capitalizeEveryWord, interpolate } from 'src/helpers/functions';
import { palette, typography, ifWeb } from 'src/styles';

import { Icon } from '../Icon/Icon';
import { LoadingIndicator } from '../LoadingIndicator';
import { Pressable } from '../Pressable';
import { StyledText } from '../StyledText';

type Colors = { primary: string; secondary?: string; content: string };

export interface ButtonColors {
  default: Colors;
  active: Colors;
  disabled: Colors;
}

type Size = 'normal' | 'medium' | 'small' | 'tiny';
type Variant = 'primary' | 'secondary' | 'flat' | 'gradient';

export interface BaseButtonProps extends React.PropsWithChildren {
  title: string;
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  titleWrapperStyle?: StyleProp<ViewStyle>;
  hoverStyle?: StyleProp<ViewStyle>;
  disabledSyle?: StyleProp<ViewStyle>;
  secondaryInnerContainerStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  testID?: string;
  loadingTitle?: string;
  size?: Size;
  variant?: Variant;
  colors?: ButtonColors;
  icon?: IconName;
  paddingHorizontal?: number;
  height?: number;
  onHover?(): void;
  onHoverOut?(): void;
}

const sizes: Record<Size, number> = {
  normal: 52,
  medium: 40,
  small: 32,
  tiny: 30,
};

export const BaseButtonNew: React.FC<BaseButtonProps> = ({
  title,
  containerStyle,
  innerContainerStyle,
  titleStyle,
  titleWrapperStyle,
  hoverStyle,
  disabledSyle,
  secondaryInnerContainerStyle,
  loading,
  onPress,
  fullWidth,
  testID,
  loadingTitle,
  icon,
  size = 'normal',
  variant = 'primary',
  paddingHorizontal,
  children,
  onHover,
  onHoverOut,
  ...rest
}) => {
  const { primary } = useTheme();
  const { isPro } = useUserInfo();

  const disabled = rest.disabled || loading;

  const colors =
    variant === 'gradient'
      ? {
          default: { primary: primary, content: palette.white },
          active: { primary: primary, content: palette.white },
          disabled: { primary: palette.grey2, content: palette.white },
        }
      : rest.colors;
  const height = rest.height ?? sizes[size];

  const titleInUppercase = !(variant === 'gradient' || size === 'tiny');

  const getTitleStyles = (active?: boolean) => [
    styles.title,
    size === 'normal' && styles.titleNormalSize,
    size === 'medium' && typography.primaryButton,
    size === 'small' && typography.body1Bold,
    size === 'tiny' && styles.titleTiny,
    !titleInUppercase && styles.titleNotUppercase,
    { color: colors?.default.content },
    active && !disabled && { color: colors?.active.content },
    disabled && { color: colors?.disabled.content },
    titleStyle,
  ];

  const getFormattedTitle = (title: string) => (!titleInUppercase ? capitalizeEveryWord(title) : title);
  const _disabledSyle = disabledSyle ? disabledSyle : { backgroundColor: colors?.disabled.primary };
  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      testID={testID}
      onHover={onHover}
      onHoverOut={onHoverOut}
    >
      {(hovered, pressed, focused) => (
        <View style={[styles.buttonContainer, fullWidth && styles.fullWidth, { height }, containerStyle]}>
          <View
            style={[
              styles.button,
              { backgroundColor: colors?.default.primary },
              secondaryInnerContainerStyle && secondaryInnerContainerStyle,
              (hovered || pressed || focused) && !loading && !disabled && [hoverStyle && hoverStyle],
              disabled && _disabledSyle,
              !!paddingHorizontal && { paddingHorizontal },
              innerContainerStyle,
            ]}
          >
            {variant === 'gradient' && !loading && !disabled && (
              <>
                <GradientBar
                  gradientType={isPro || isStandardsDomain() ? 'standardsGradient' : 'proGradient'}
                  style={StyleSheet.absoluteFill}
                />
                <View
                  style={[
                    styles.gradientOverlay,
                    (hovered || pressed || focused) && styles.gradientOverlayActive,
                  ]}
                />
              </>
            )}
            <View
              style={[styles.titleWrapper, size === 'tiny' && styles.titleWrapperTiny, titleWrapperStyle]}
            >
              {children}
              {loading ? (
                <>
                  {loadingTitle && (
                    <StyledText style={[getTitleStyles(), styles.marginRight]}>
                      {getFormattedTitle(loadingTitle)}
                    </StyledText>
                  )}
                  <LoadingIndicator
                    size={size === 'tiny' ? 10 : 20}
                    color={disabled ? colors?.disabled.content : colors?.default.content}
                  />
                </>
              ) : (
                <>
                  <StyledText style={getTitleStyles(hovered || pressed)} testID="button-text">
                    {getFormattedTitle(title)}
                  </StyledText>
                  {icon && (
                    <Icon
                      style={styles.icon}
                      width={interpolate([sizes.tiny, sizes.normal], [15, 20], height)}
                      color={
                        disabled
                          ? colors?.disabled.content
                          : hovered || pressed
                          ? colors?.active.content
                          : colors?.default.content
                      }
                      name={icon}
                    />
                  )}
                </>
              )}
            </View>
          </View>
        </View>
      )}
    </Pressable>
  );
};

export const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  buttonContainer: {
    borderRadius: 4,
    ...ifWeb({
      userSelect: 'none',
      transitionProperty: 'background-color',
    }),
  },
  button: {
    flex: 1,
    borderRadius: 4,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    ...ifWeb({
      transitionProperty: 'background-color, border-color',
    }),
  },
  titleWrapper: {
    paddingHorizontal: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleWrapperTiny: {
    paddingHorizontal: 12,
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  titleNotUppercase: {
    textTransform: 'none',
  },
  titleNormalSize: {
    fontSize: 18,
    lineHeight: 18,
    ...typography.weightBold,
  },
  titleTiny: {
    ...typography.body1ShortSemiBold,
  },
  marginRight: {
    marginRight: 8,
  },
  icon: {
    marginLeft: 10,
  },
  gradientOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: palette.navy,
    opacity: 0,
    ...ifWeb({
      transitionProperty: 'opacity',
    }),
  },
  gradientOverlayActive: {
    opacity: 1,
    ...ifWeb({
      transitionProperty: 'opacity',
    }),
  },
});

export const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards || isStandardsDomain() ? BaseButtonNew : BaseButtonOld;

  return <Component {...props} />;
};
