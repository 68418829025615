import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { PLUMBS_SUPPORT_LINK, STANDARDS_SUPPORT_LINK } from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { typography } from 'src/styles';

import { ErrorBannerTemplate } from './ErrorBannerTemplate';

interface Props {}

export const ServerIssueBanner: React.FC<Props> = () => {
  const { primary } = useTheme();
  const { isPro } = useUserInfo();
  return (
    <ErrorBannerTemplate
      header={i18n.t('errors:header500')}
      description={
        <Trans
          i18nKey="errors:serverIssueDescription"
          components={{
            p: <StyledText />,
            contact: (
              <Link
                to={isPro ? STANDARDS_SUPPORT_LINK : PLUMBS_SUPPORT_LINK}
                external
                wrapper="text"
                style={[{ color: primary }, { textDecorationColor: primary }, styles.link]}
              />
            ),
          }}
        />
      }
      descriptionStyle={styles.description}
      icon="oops"
      iconWidth={157}
      iconHeight={82}
      testID="server-issue-banner"
    />
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
    ...typography.body2Bold,
  },
  description: {
    maxWidth: 320,
  },
});
