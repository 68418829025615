import React from 'react';
import { AccessibilityRole, StyleSheet, Text, TextProps } from 'react-native';
import Animated, { useAnimatedRef } from 'react-native-reanimated';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { highlightMatchingText } from 'src/features/findOnPage/helper';
import { useFindOnPage } from 'src/features/findOnPage/hooks/useFindOnPage';
import { isWeb } from 'src/helpers';

import { fonts, palette } from '../styles';

interface Props extends Omit<React.PropsWithChildren<TextProps>, 'numberOfLines'> {
  singleLine?: boolean;
  noDefaultStyle?: boolean;
  headerLevel?: null | number;
  ignoreFindOnPageSearch?: boolean;
}

export const StyledText = React.forwardRef<Text, Props>(
  (
    {
      maxFontSizeMultiplier = DEFAULT_MAX_FONT_SIZE_MULTIPLIER,
      style,
      testID,
      singleLine,
      noDefaultStyle,
      headerLevel,
      children,
      ignoreFindOnPageSearch,
      ...rest
    },
    ref,
  ) => {
    const findOnPage = useFindOnPage();
    const searchText = findOnPage?.searchText;
    const parentRef = useAnimatedRef<Animated.Text>();

    const processedChildren =
      !ignoreFindOnPageSearch && searchText && findOnPage?.scrollViewRef
        ? highlightMatchingText(children, searchText, parentRef)
        : children;

    const accessibilityProps: Record<string, any> = {};

    if (headerLevel) {
      accessibilityProps.accessibilityRole = 'header' as AccessibilityRole;
      accessibilityProps.accessibilityLabel = `Heading level ${headerLevel}`;
      if (isWeb) {
        accessibilityProps['aria-level'] = headerLevel;
      }
    }

    return (
      // eslint-disable-next-line react/forbid-elements
      <Text
        {...rest}
        {...accessibilityProps}
        ref={searchText ? parentRef : ref}
        style={[!noDefaultStyle && styles.text, style]}
        maxFontSizeMultiplier={maxFontSizeMultiplier}
        testID={testID}
        numberOfLines={singleLine ? 1 : undefined}
      >
        {processedChildren}
      </Text>
    );
  },
);

StyledText.displayName = 'StyledText';

export const styles = StyleSheet.create({
  text: {
    fontFamily: fonts.sourceSans,
    color: palette.grey8,
  },
});
