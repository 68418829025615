import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components';
import { palette, typography } from 'src/styles';

import { getClassificationData } from '../helpers';
import { DICClassificationKey } from '../types';

export interface Props {
  type: DICClassificationKey;
  style?: StyleProp<ViewStyle>;
}

export const DICLegendLabel: React.FC<Props> = ({ type, style }) => {
  const classificationData = getClassificationData(type);

  if (!classificationData) {
    return null;
  }

  const backgroundColor = classificationData.color;
  const badgeStyle = [styles.badge, { backgroundColor }];

  return (
    <View style={[styles.wrapper, style]}>
      <View style={badgeStyle} testID="dic-classification-label">
        <StyledText maxFontSizeMultiplier={1} style={styles.text} singleLine headerLevel={2}>
          {classificationData.label}
        </StyledText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 1,
  },
  badge: {
    height: 28,
    paddingVertical: 4,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
  },
  text: {
    textTransform: 'uppercase',
    color: palette.white,
    ...typography.text2,
    ...typography.weightBold,
  },
});
