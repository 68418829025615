import { onlineManager } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserInfo } from 'src/features/auth/hooks';
import { showNotification } from 'src/helpers';

export const useInternetConnectionNotification = () => {
  const [isOnline, setOnline] = useState(onlineManager.isOnline());
  const wasErrorDisplayed = useRef(false);
  const { isPro } = useUserInfo();

  const { t } = useTranslation('errors');

  useEffect(() => {
    onlineManager.subscribe(setOnline);
  }, []);

  useEffect(() => {
    if (isOnline && !wasErrorDisplayed.current) return;
    setTimeout(() => {
      showNotification({
        title: isOnline
          ? t('internetRestoredNotification')
          : isPro
          ? t('noInternetStandardsNotification')
          : t('noInternetPlumbsNotification'),
        type: isOnline ? 'success' : 'error',
        autoHide: isOnline,
      });
      if (!isOnline) {
        wasErrorDisplayed.current = true;
      }
    }, 100);
  }, [isOnline, isPro, t]);
};
