import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable, StyleSheet, View } from 'react-native';

import { BaseModal } from 'src/components';
import { IconTextButton } from 'src/components/IconTextButton';
import { StyledText } from 'src/components/StyledText';
import { HELP_UPGRADE_PLAN_LINK } from 'src/constants/externalLinks';
import { ModalPortal } from 'src/contexts/portals';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { WebSocketService } from 'src/services';
import { ifWeb, palette, typography } from 'src/styles';

import { DeviceCard } from './DeviceCard';
import { useDevicesListFromWebSocket } from '../hooks';
import { DevicesList } from '../types';

export const ActiveDevicesModal: React.FC = () => {
  const { devicesList, isCurrentActive } = useDevicesListFromWebSocket();

  if (isCurrentActive) {
    return null;
  }

  return <Content devicesList={devicesList} />;
};

interface Content {
  devicesList: DevicesList | null;
}

const Content: React.FC<Content> = ({ devicesList }) => {
  const { primary } = useTheme();
  const { isTablet } = useDeviceInfo();
  const [isLoading, setLoading] = useState(false);
  const [isLogoutAllLoading, setLogoutAllLoading] = useState(false);
  const areButtonsDisabled = isLoading || isLogoutAllLoading;
  const { isPro } = useUserInfo();

  const { t } = useTranslation('sessionsLimit');

  const logOutAllHandler = () => {
    setLogoutAllLoading(true);
    WebSocketService.send({ action: 'logout_all_devices' });
  };

  return (
    <ModalPortal>
      <BaseModal padded={false}>
        <View style={styles.container} testID="active-sessions-modal-wrapper">
          <StyledText style={[typography.weightSemiBold, styles.title]}>{t('activeSessions')}</StyledText>
          <StyledText style={typography.text2}>
            {isPro ? t('activeSessionsStandardsDisclaimer') : t('activeSessionsPlumbsDisclaimer')}
          </StyledText>
          <View>
            {devicesList?.map((device) => (
              <DeviceCard
                key={device.id}
                sessionInfo={device}
                onPress={() => setLoading(true)}
                disabled={areButtonsDisabled}
              />
            ))}
          </View>
          <View style={[isTablet && styles.row, styles.title, isTablet && styles.tabletSessionUpgrade]}>
            <IconTextButton
              disabled={areButtonsDisabled}
              isLoading={isLogoutAllLoading}
              onPress={logOutAllHandler}
              text={t('logOutFromAllDevices')}
              icon="chevron-right"
              iconPosition="right"
              iconWidth={12}
              iconColor={isLoading ? styles.logoOutButtonStyleDisabled.color : undefined}
              textStyle={[
                typography.body3SemiBold,
                { color: primary },
                areButtonsDisabled && styles.logoOutButtonStyleDisabled,
              ]}
              testID="active-sessions-sign-out-all-button"
            />
            <View style={[styles.row, !isTablet && styles.descriptionSection]}>
              <StyledText style={typography.text2}>{t('needMoreSessions') + ' '}</StyledText>
              <Pressable onPress={() => Linking.openURL(HELP_UPGRADE_PLAN_LINK)}>
                <StyledText style={[typography.text2, { color: primary }]}>{t('upgradePlan')}</StyledText>
              </Pressable>
              <StyledText style={typography.text2}>.</StyledText>
            </View>
          </View>
        </View>
      </BaseModal>
    </ModalPortal>
  );
};

export const styles = StyleSheet.create({
  container: {
    ...ifWeb({ width: '100%' }, {}),
    paddingHorizontal: 16,
  },
  title: {
    marginVertical: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabletSessionUpgrade: {
    justifyContent: 'space-between',
  },
  descriptionSection: {
    marginTop: 16,
  },
  logoOutButtonStyleDisabled: {
    color: palette.grey2,
  },
});
