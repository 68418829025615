import { useTranslation } from 'react-i18next';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { StandardsLogo } from 'src/assets/images/logos';
import { Pressable, StyledText, GradientBar } from 'src/components';
import {
  APPCUES_PRO_TOUR_FLOW_ID,
  GETTING_STARTED_LINK,
  EXPLORE_STANDARDS_LINK,
} from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { AppcuesService } from 'src/features/tracking';
import { isWeb } from 'src/helpers';
import { ProPromoButton as ProPromoButtonOld } from 'src/navigation/components/SideBar/ProPromoButton.old';
import { ifWeb, palette } from 'src/styles';

import { Link } from '../Link';

interface Props {
  gradientBackground?: boolean;
  shadow?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const ProPromoButtonNew: React.FC<Props> = ({ shadow, gradientBackground, style }) => {
  const { t } = useTranslation('navigation');
  const { isPro } = useUserInfo();

  const link = isPro ? GETTING_STARTED_LINK : EXPLORE_STANDARDS_LINK;

  const openAppcuesFlow = () => {
    AppcuesService.displayFlow(APPCUES_PRO_TOUR_FLOW_ID);
  };

  const content = (isHovered: boolean) => (
    <View
      style={[
        styles.wrapper,
        shadow && styles.wrapperShadow,
        isHovered && styles.wrapperShadowHovered,
        style,
      ]}
    >
      {gradientBackground && (
        <GradientBar
          style={styles.background}
          gradientType={
            isPro
              ? isHovered
                ? 'standardsHoverGradient'
                : 'standardsGradient'
              : isHovered
              ? 'standardsGradient'
              : 'proGradient'
          }
        />
      )}
      <StyledText
        style={[
          styles.text,
          gradientBackground && styles.textGradientBg,
          !gradientBackground && isHovered && styles.textHoveredBg,
          !isPro && !gradientBackground && styles.textMargin,
        ]}
      >
        {t(isPro ? 'tourStandards' : 'explore')} {!isPro && gradientBackground && 'Standards'}
      </StyledText>
      {!isPro && !gradientBackground && <StandardsLogo width={96} />}
    </View>
  );

  if (isPro && isWeb) {
    return <Pressable onPress={openAppcuesFlow}>{content}</Pressable>;
  }

  return (
    <Link to={link} external>
      {content}
    </Link>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.white,
    borderRadius: 5,
    paddingHorizontal: 16,
    overflow: 'hidden',
    height: 40,
    transition: '250ms all',
  },
  wrapperShadow: {
    ...ifWeb({
      boxShadow: '0px 0px 15px 0px rgba(10, 36, 71, 0.20)',
    }),
  },
  wrapperShadowHovered: {
    ...ifWeb({
      boxShadow: '0px 0px 20px 0px rgba(10, 36, 71, 0.30)',
    }),
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -1,
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: '700',
    color: palette.darkBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textGradientBg: {
    color: palette.white,
  },
  textHoveredBg: {
    color: palette.navy,
  },
  textMargin: {
    marginRight: 5,
  },
});

export const ProPromoButton: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? ProPromoButtonNew : ProPromoButtonOld;

  return <Component {...props} />;
};
