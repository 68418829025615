import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import {
  Container,
  FullScreenTemplate,
  MobileContentHeader,
  StyledText,
  TabsSelector,
  InfoBox,
} from 'src/components';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { isDrugHandout } from 'src/helpers';
import { IS_PRINTING_PDF_WITH_IFRAME } from 'src/helpers/pdf';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';
import { AppDispatch } from 'src/state/store';
import { CONTAINER_PADDING_H_MOBILE, palette, typography } from 'src/styles';

import { DownloadHandouts } from '../components/DownloadHandouts';
import { EmailHandouts } from '../components/EmailHandouts';
import { HandoutsLanguageSelector } from '../components/HandoutsLanguageSelector';
import { PrintHandouts } from '../components/PrintHandouts';
import { SelectedHandoutsList } from '../components/SelectedHandoutsList';
import { useHandoutsToShare } from '../hooks';
import { clearHandoutsSelection } from '../state';
import { HandoutsLanguageType, ShareHandoutsFormProps } from '../types';

type Section = 'email' | 'print' | 'download';

type Props = ScreenProps<Route.SelectedHandouts> | ScreenProps<Route.ShareHandout>;
const DEFAULT_LANGUAGE = HandoutsLanguageType.English;

export const SelectedHandoutsScreen: React.FC<Props> = ({ route, navigation }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('handouts');
  const { isTablet } = useDeviceInfo();
  const { isMobileViewMode } = useWebAppLayout();
  const { hasAccessToEmailHandouts } = useUserInfo();
  const { error, isLoading, handouts, singleHandoutMode, fetchStatus } = useHandoutsToShare();

  const [activeSection, setSection] = useState<Section>();
  const [language, setLanguage] = useState<HandoutsLanguageType>(DEFAULT_LANGUAGE);

  const onShareSuccess = () => {
    /** Redirecting breaks the printing process when using iFrame solution */
    const shouldRedirect = !IS_PRINTING_PDF_WITH_IFRAME;
    if (route.name === Route.SelectedHandouts) {
      dispatch(clearHandoutsSelection());
    }

    if (shouldRedirect) {
      if (handouts.length === 1) {
        const { id, contentType } = handouts[0];
        navigation.navigate(
          isDrugHandout({ contentType }) ? Route.DrugHandoutItem : Route.ClinicalHandoutItem,
          { id },
        );
      } else {
        navigation.navigate(
          isDrugHandout(handouts[0]) ? Route.DrugHandoutsList : Route.ClinicalHandoutsList,
        );
      }
    }
  };

  const LanguageSelector = <HandoutsLanguageSelector value={language} onChange={setLanguage} />;
  const noDataToDisplay = singleHandoutMode && handouts.length === 0;
  const areFormsDisabled = handouts.length === 0;
  const formProps: ShareHandoutsFormProps = { onShareSuccess, language };

  const isLanguageSelectorAvailable = checkifLanguageSelectorIsAvailable();
  const isLanguageDisclaimerAvailable = checkifLanguageDisclaimerIsAvailable();

  const drugHandoutsOnlyEnglish = handouts
    .filter((item) => !item.hasSpanishVersion)
    .map((item) => item.title);

  const drugHandoutsWithSpanish = handouts
    .filter((item) => item.hasSpanishVersion)
    .map((item) => item.title);

  function checkifLanguageSelectorIsAvailable() {
    const hasDrugHandoutWithSpanish = handouts.some(
      (item) => isDrugHandout(item) && item.hasSpanishVersion,
    );
    // Language selector only shows when at least one selected handout has spanish version.
    if (hasDrugHandoutWithSpanish) return true;
    return false;
  }

  function checkifLanguageDisclaimerIsAvailable() {
    // Language disclaimer only shows when the language selector is available and there's more then one handout.
    if (!isLanguageSelectorAvailable || handouts.length <= 1) {
      return false;
    }

    const allHandoutsHaveSpanish = handouts.every((item) => item.hasSpanishVersion);
    const isSpanishSelected = language === 'spanish';
    const isBothSelected = language === 'spanish-english';

    // Show language disclaimer if theres a mix of language versions on selected handouts and user selected spanish or both.
    if (!allHandoutsHaveSpanish && (isSpanishSelected || isBothSelected)) {
      return true;
    }

    // Don't show language disclaimer if all handouts have spanish version and user selected spanish.
    if (allHandoutsHaveSpanish && isSpanishSelected) {
      return false;
    }

    return false;
  }

  useEffect(() => {
    if (isLanguageSelectorAvailable) {
      setLanguage(DEFAULT_LANGUAGE);
    }
  }, [isLanguageSelectorAvailable]);

  return (
    <FullScreenTemplate
      title={t(singleHandoutMode ? 'shareHandoutHeader' : 'selectedHandoutsHeader')}
      showBackButton
      contentContainerStyle={styles.screen}
      isLoading={isLoading}
      testID="selected-handouts-screen"
    >
      <QueryErrorBanner isDataAvailable={!noDataToDisplay} error={error} fetchStatus={fetchStatus} />
      {!noDataToDisplay && (
        <Container fullWidthOnMobile verticalPadding maxWidth="narrow">
          <SelectedHandoutsList />
          <Container style={[styles.languageContainer, isMobileViewMode && styles.languageContainerMobile]}>
            {isLanguageSelectorAvailable && LanguageSelector}
            {isLanguageDisclaimerAvailable && (
              <InfoBox style={[styles.infoBox]} testID="handouts-language-selector-disclaimer">
                <View style={[styles.box]}>
                  <Trans
                    i18nKey="handouts:languageSelectorDisclaimer"
                    components={{
                      p1: (
                        <StyledText
                          style={[styles.paragraph, typography.weightSemiBold, typography.text3]}
                        />
                      ),
                      p: <StyledText style={[styles.paragraph, styles.paragraphMargin]} />,
                      b: <StyledText style={typography.weightBold} />,
                      spanishList: (
                        <View style={styles.ul}>
                          {drugHandoutsWithSpanish.map((handout, idx) => (
                            <StyledText key={idx} style={styles.li}>
                              {'\u2022'} <StyledText style={styles.liText}>{handout}</StyledText>
                            </StyledText>
                          ))}
                        </View>
                      ),
                      englishList: (
                        <View style={styles.ul}>
                          {drugHandoutsOnlyEnglish.map((handout, idx) => (
                            <StyledText key={idx} style={styles.li}>
                              {'\u2022'} <StyledText style={styles.liText}>{handout}</StyledText>
                            </StyledText>
                          ))}
                        </View>
                      ),
                    }}
                  />
                </View>
              </InfoBox>
            )}
          </Container>
          <View style={[styles.shareContainer, isTablet && styles.shareContainerTablet]}>
            <MobileContentHeader style={styles.header}>{t('common:share')}</MobileContentHeader>
            <TabsSelector
              items={[
                {
                  id: 'email',
                  title: t('common:email'),
                  isHidden: !hasAccessToEmailHandouts,
                  testID: 'email-handouts-tab',
                },
                {
                  id: 'print',
                  title: t('common:print'),
                  testID: 'print-handouts-tab',
                },
                {
                  id: 'download',
                  title: t('common:download'),
                  testID: 'download-handouts-tab',
                },
              ]}
              selected={areFormsDisabled ? undefined : activeSection}
              onPress={(id) => setSection(id)}
              vertical={!isTablet}
              disabled={areFormsDisabled}
            />
            {!areFormsDisabled && (
              <View style={styles.formWrapper}>
                {activeSection === 'email' && <EmailHandouts {...formProps} />}
                {activeSection === 'print' && <PrintHandouts {...formProps} />}
                {activeSection === 'download' && <DownloadHandouts {...formProps} />}
              </View>
            )}
          </View>
        </Container>
      )}
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  screen: {
    backgroundColor: palette.white,
  },
  header: {
    marginBottom: 12,
  },
  shareContainer: {
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    marginTop: 30,
  },
  shareContainerTablet: {
    paddingHorizontal: 0,
  },
  formWrapper: {
    marginTop: 28,
  },
  languageContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 40,
    marginLeft: 0,
    marginRight: 0,
  },
  languageContainerMobile: {
    flexDirection: 'column',
  },
  infoBox: {
    width: '100%',
    flex: 1,
    maxWidth: 550,
    marginTop: 25,
    marginHorizontal: 0,
  },
  box: {
    ...typography.body2,
    backgroundColor: palette.grey1,
    borderRadius: 5,
    padding: 2,
    color: palette.navy,
  },
  paragraph: {
    ...typography.body2,
  },
  paragraphMargin: {
    marginTop: 5,
    marginBottom: 0,
  },
  ul: {
    marginTop: 0,
    marginBottom: 0,
    marginVertical: 8,
    paddingLeft: 10,
  },
  li: {
    marginVertical: 4,
    marginBottom: 0,
  },
  liText: {
    paddingLeft: 8,
  },
});
