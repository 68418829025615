import React, { useLayoutEffect, useState } from 'react';

import { WebScreenContext } from 'src/contexts/webScreenContext';
import { useUserInfo } from 'src/features/auth/hooks';
import { isStandardsDomain } from 'src/helpers';
import { useNavigation, useRoute } from 'src/navigation/hooks';
import { NavigationProp, ScreenOptions, ScreenProps } from 'src/navigation/types';

import { Route } from '../routes';

interface Props extends ScreenOptions {
  Component: React.ComponentType<ScreenProps<any>>;
  options?: {
    title?: string;
  };
}

const Screen: React.FC<Props> = ({ Component, getId }) => {
  const navigation = useNavigation<NavigationProp<Route>>();
  const route = useRoute();

  const key = getId?.({ params: route.params });

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [key]);

  return <Component key={key} navigation={navigation} route={route} />;
};

export const WebScreen: React.FC<Props> = React.memo(({ options, ...rest }) => {
  const state = useState(options ?? {});
  const { isPro } = useUserInfo();

  useLayoutEffect(() => {
    const title = isStandardsDomain() || isPro ? 'Standards™' : 'Plumb’s™';
    const icoFav = document.querySelector('link[href="/plumbs-favicon.ico"]');
    const svgFav = document.querySelector('link[href="/plumbs-icon.svg"]');
    const appleFav = document.querySelector('link[href="/plumbs-apple-touch-icon.png"]');
    window.document.title = state[0].title || title;
    if (isPro || isStandardsDomain()) {
      icoFav && icoFav.setAttribute('href', '/standards-favicon.ico');
      svgFav && svgFav.setAttribute('href', '/standards-icon.svg');
      appleFav && appleFav.setAttribute('href', '/standards-apple-touch-icon.png');
    }
  }, [state, isPro]);

  return (
    <WebScreenContext.Provider value={state}>
      <Screen {...rest} />
    </WebScreenContext.Provider>
  );
});
