import { ContentType } from 'src/features/content/constants';
import { isLocal, isProduction, isStandardsDomain, isTest, isUAT, isWeb } from 'src/helpers';

import { GroupRole, Permission } from './enums';
import type { GroupInfo, GroupInfoCurrentMember } from './types';
import { GroupMemberAccessLevel } from '../adminDashboard/types';
import { Contents } from '../content/config';
import { ContentTypeSimplified } from '../content/types';

// note that admins are also group members
export const isUserAGroupMember = (groupInfo: GroupInfo | null): groupInfo is GroupInfoCurrentMember => {
  return !!groupInfo && !groupInfo.removed;
};

export const isUserAGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export const isUserANormalGroupMember = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.MEMBER;
};

export const isUserASelfServeInstantConversion = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.isSelfServeInstantConversion;
};

/** User is a group member with limited access to content */
export const doesUserHaveLimitedProAccess = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.accessLevel === GroupMemberAccessLevel.ProLimited;
};

export const isUserAManagedGroupMember = (
  groupInfo: GroupInfo | null,
): groupInfo is GroupInfoCurrentMember => {
  return isUserAGroupMember(groupInfo) && !!groupInfo.isManagedGroup;
};

export const isUserAManagedGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAManagedGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export function getPermissionsByContentType(contentType: ContentType | ContentTypeSimplified): {
  access: Permission;
  display: Permission;
} {
  switch (Contents.getSimplifiedType(contentType)) {
    case 'algorithm': {
      return {
        access: Permission.FullAccessAlgorithms,
        display: Permission.DisplayAlgorithms,
      };
    }
    case 'dx-tx': {
      return {
        access: Permission.FullAccessClinicalBriefs,
        display: Permission.DisplayClinicalBriefs,
      };
    }
    case 'clinical-handout': {
      return {
        access: Permission.FullAccessClinicalHandouts,
        display: Permission.DisplayClinicalHandouts,
      };
    }
    case 'drug-handout': {
      return {
        access: Permission.FullAccessDrugHandouts,
        display: Permission.FullAccessDrugHandouts,
      };
    }

    case 'appendix': {
      return {
        access: Permission.FullAccessAppendix,
        display: Permission.DisplayAppendix,
      };
    }

    case 'drug': {
      return {
        access: Permission.FullAccessDrugs,
        display: Permission.DisplayDrugs,
      };
    }

    case 'ddx': {
      return {
        access: Permission.FullAccessDDx,
        display: Permission.DisplayDDx,
      };
    }
  }
}

const getRedirectUrl = (type: 'standards' | 'plumbs'): string | null => {
  const urlRoute = 'auth';
  const redirectUrls = {
    standards: {
      local: `http://localhost:3005/${urlRoute}`,
      test: `https://standards.instinctvet.app/${urlRoute}`,
      uat: `https://standards.instinctvet.net/${urlRoute}`,
      production: `https://standards.instinctvet.com/${urlRoute}`,
      dev: `https://standards.instinctvet.dev/${urlRoute}`,
    },
    plumbs: {
      local: `http://localhost:3000/${urlRoute}`,
      test: `https://eng-test.plumbstest.com/${urlRoute}`,
      uat: `https://plumbstest.com/${urlRoute}`,
      production: `https://app.plumbs.com/${urlRoute}`,
    },
  };
  if (isLocal) return redirectUrls[type].local;
  if (isUAT) return redirectUrls[type].uat;
  if (isTest) return redirectUrls[type].test;
  if (isProduction) return redirectUrls[type].production;
  return null;
};

export const loginRedirectUrl = (isPro: boolean) => {
  if (!isWeb) return null;

  const isOnStandardsDomain = isStandardsDomain();
  // Redirect logic
  if (isPro && !isOnStandardsDomain) {
    return getRedirectUrl('standards');
  }

  if (!isPro && isOnStandardsDomain) {
    return getRedirectUrl('plumbs');
  }

  return null;
};
