import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, View } from 'react-native';

import image from 'src/assets/images/standards-devices.gif';
import { ProPaywallModal as ProPaywallModalOld } from 'src/components/ProPaywallModal.old';
import { EXPLORE_STANDARDS_LINK } from 'src/constants/externalLinks';
import { ModalPortal } from 'src/contexts/portals';
import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { palette, typography } from 'src/styles';

import { BaseModal } from './BaseModal';
import { PrimaryButton } from './Buttons/PrimaryButton';
import { SecondaryButton } from './Buttons/SecondaryButton';
import { Image } from './Image';
import { StyledText } from './StyledText';

interface Props {
  close: () => any;
}

export const ProPaywallModalNew: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation('paywall');
  const handleLearnMorePress = () => {
    isWeb ? window.open(EXPLORE_STANDARDS_LINK, '_blank') : Linking.openURL(EXPLORE_STANDARDS_LINK);
  };

  return (
    <ModalPortal>
      <BaseModal close={close} padded style={styles.modal} testID="paywall-modal">
        <View style={styles.container}>
          <View style={styles.imageWrapper}>
            <Image source={image} style={styles.image} resizeMode="cover" />
          </View>
          <StyledText style={[typography.body5Bold, styles.header]}>{t('header')}</StyledText>
          <StyledText style={[typography.body2, styles.textAlign]}>{t('description')}</StyledText>
          <View style={styles.buttons}>
            <SecondaryButton
              title={t('maybeLater')}
              onPress={close}
              containerStyle={[styles.button]}
              innerContainerStyle={styles.buttonPadding}
              testID="maybe-later-btn"
            />
            <PrimaryButton
              title={t('tellMeMore')}
              onPress={handleLearnMorePress}
              containerStyle={[styles.button]}
              innerContainerStyle={styles.buttonPadding}
            />
          </View>
        </View>
      </BaseModal>
    </ModalPortal>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: 500,
  },
  container: {
    maxWidth: 500,
    alignItems: 'center',
    flexGrow: 0,
  },
  imageWrapper: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  image: {
    width: 185,
    height: 110,
  },
  header: {
    color: palette.navy,
    marginBottom: 16,
  },
  buttons: {
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textAlign: {
    textAlign: 'center',
  },
  button: {
    margin: 8,
    flexGrow: 1,
  },
  buttonPadding: {
    paddingHorizontal: 20,
  },
});

export const ProPaywallModal: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? ProPaywallModalNew : ProPaywallModalOld;

  return <Component {...props} />;
};
