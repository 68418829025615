import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { View, StyleSheet, Linking } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { EXPLORE_STANDARDS_LINK } from 'src/constants/externalLinks';
import { BottomFixedElementsPortal } from 'src/contexts/portals';
import { useUserInfo } from 'src/features/auth/hooks';
import { ContentType } from 'src/features/content/constants';
import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { getShadow, ifWeb, CONTAINER_PADDING_H_MOBILE, palette } from 'src/styles';
import { typography } from 'src/styles/typography';

import { IconButton } from './IconButton';

interface Props {
  contentTypes: readonly ContentType[];
}

export const ProInfoBanner: React.FC<Props> = React.memo(({ contentTypes }) => {
  const { isSmallMobile } = useDeviceInfo();
  const { t } = useTranslation('paywall');
  const { hasAccessToContentType } = useUserInfo();
  const [shouldShowBanner, setShouldShowBanner] = useState(!contentTypes.every(hasAccessToContentType));

  const learnMorePressHandler = () =>
    isWeb ? window.open(EXPLORE_STANDARDS_LINK, '_blank') : Linking.openURL(EXPLORE_STANDARDS_LINK);

  const content = (
    <View style={[styles.container]} testID="paywall-banner">
      <View style={styles.headerWrapper}>
        <StyledText
          style={[
            styles.header,
            styles.blueText,
            typography.body5Bold,
            isSmallMobile && typography.body2Bold,
          ]}
        >
          {t('bannerHeaderOld')}
        </StyledText>
        <IconButton
          name="close"
          onPress={() => setShouldShowBanner(false)}
          containerStyle={styles.closeIcon}
          color={palette.blue}
          width={15}
        />
      </View>
      <Trans
        i18nKey={isWeb ? 'bannerDescriptionOld' : 'bannerDescriptionNativeOld'}
        ns="paywall"
        components={{
          p: <StyledText style={styles.description} />,
          goPro: <StyledText onPress={learnMorePressHandler} style={styles.blueText} />,
        }}
      />
    </View>
  );

  return shouldShowBanner ? (
    isWeb ? (
      <BottomFixedElementsPortal>{content}</BottomFixedElementsPortal>
    ) : (
      content
    )
  ) : null;
});

export const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingBottom: 20,
    alignItems: 'center',
    backgroundColor: palette.white,
    width: '100%',
    minHeight: 116,
    ...ifWeb(
      {
        paddingHorizontal: 24,
        justifyContent: 'center',
        ...getShadow(2),
      },
      {
        paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
        width: '100%',
        justifyContent: 'flex-end',
        borderTopWidth: 1,
        borderTopColor: palette.grey2,
      },
    ),
  },
  headerWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  header: {
    textAlign: 'center',
    marginBottom: 16,
    maxWidth: '90%',
  },
  blueText: {
    color: palette.blue,
  },
  description: {
    textAlign: 'center',
  },
});
