import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { FlatButton, GradientButton } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { NavigationMenuButton } from 'src/navigation/types';
import { fonts, palette } from 'src/styles';

interface Props {
  containerStyles?: StyleProp<ViewStyle>;
  inMobileDropdown?: boolean;
}

export const NavigationButtons: React.FC<Props> = ({ containerStyles, inMobileDropdown }) => {
  const { isSmallMobile } = useDeviceInfo();

  const buttons: NavigationMenuButton[] = [
    {
      key: 'sign-in',
      to: { route: Route.SignIn },
      text: i18n.t('screens:signIn'),
      type: 'flat',
    },
    {
      key: 'get-started',
      to: { route: Route.SignUp },
      text: i18n.t('navigation:subscribe'),
      type: 'primary',
    },
  ];

  if (!buttons.length) {
    return null;
  }

  return (
    <View
      style={[
        styles.wrapper,
        inMobileDropdown && styles.wrapperMobile,
        isSmallMobile && styles.wrapperSmallMobile,
        containerStyles,
      ]}
    >
      {buttons.map((button) => (
        <View
          style={[
            styles.buttonWrapper,
            inMobileDropdown && styles.buttonWrapperMobile,
            isSmallMobile && styles.buttonWrapperSmallMobile,
          ]}
          key={button.key}
        >
          <Link to={button.to!} external={button.external}>
            {button.type === 'flat' ? (
              <FlatButton
                key={button.key}
                title={button.text}
                variant={button.type}
                titleStyle={styles.buttonTitle}
                height={46}
                fullWidth={inMobileDropdown}
                innerContainerStyle={inMobileDropdown && styles.textButtonMobile}
              />
            ) : (
              <GradientButton
                key={button.key}
                title={button.text}
                variant={button.type}
                titleStyle={styles.buttonTitle}
                paddingHorizontal={20}
                height={46}
                fullWidth={inMobileDropdown}
                overlayBackgroundColor={inMobileDropdown ? palette.grey1 : palette.white}
              />
            )}
          </Link>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  wrapperMobile: {
    backgroundColor: palette.grey1,
    paddingVertical: 20,
  },
  wrapperSmallMobile: {
    flexWrap: 'wrap',
    paddingVertical: 10,
  },
  buttonWrapper: {
    paddingHorizontal: 10,
  },
  buttonWrapperMobile: {
    width: '100%',
    flex: 1,
  },
  buttonWrapperSmallMobile: {
    flex: undefined,
    marginBottom: 10,
  },
  textButtonMobile: {
    backgroundColor: palette.white,
  },
  buttonTitle: {
    fontFamily: fonts.din,
    fontSize: 15,
  },
});
