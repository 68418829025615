import { StyleSheet, View } from 'react-native';

import {
  CliniciansBriefGreyLogo,
  InstinctLogoGrey,
  PlumbsLogoActive,
  PlumbsLogoGrey,
  StandardsLogoActive,
  StandardsLogoGrey,
} from 'src/assets/images/logos';
import { GradientBar } from 'src/components';
import {
  CLINICIANS_BRIEFS_SITE_LINK,
  INSTINCT_SITE_LINK,
  STANDARDS_SITE_LINK,
  PLUMBS_SITE_LINK,
} from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { isStandardsDomain } from 'src/helpers';
import { palette } from 'src/styles';

import { Link } from '../Link';

export const BrandsPanel: React.FC = () => {
  const { isPro } = useUserInfo();
  return (
    <View style={styles.wrapper}>
      <GradientBar gradientType="brandsGradient" style={StyleSheet.absoluteFill} />
      <Link to={INSTINCT_SITE_LINK} external style={styles.logoWrapper}>
        <InstinctLogoGrey height={40} aria-label="Vetmedux" />
      </Link>
      <View
        style={[
          (isStandardsDomain() || isPro) && styles.logoWrapper,
          (isStandardsDomain() || isPro) && styles.activeWrapper,
        ]}
      >
        {isStandardsDomain() || isPro ? (
          <StandardsLogoActive height={40} />
        ) : (
          <Link to={STANDARDS_SITE_LINK} external style={styles.logoWrapper}>
            <StandardsLogoGrey height={40} />
          </Link>
        )}
      </View>
      <View
        style={[
          !isStandardsDomain() && !isPro && styles.logoWrapper,
          !isStandardsDomain() && !isPro && styles.activeWrapper,
        ]}
      >
        {isStandardsDomain() || isPro ? (
          <Link to={PLUMBS_SITE_LINK} external style={styles.logoWrapper}>
            <PlumbsLogoGrey height={40} />
          </Link>
        ) : (
          <PlumbsLogoActive height={40} />
        )}
      </View>
      <Link to={CLINICIANS_BRIEFS_SITE_LINK} external style={styles.logoWrapper}>
        <CliniciansBriefGreyLogo height={40} aria-label="Clinician’s Brief" />
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: 40,
    flexDirection: 'row',
  },
  logoWrapper: {
    zIndex: 1,
    paddingHorizontal: 24,
    borderRightWidth: 1,
    borderRightColor: palette.grey6,
    justifyContent: 'center',
    height: '100%',
  },
  activeWrapper: {
    backgroundColor: palette.white,
    borderRightWidth: 0,
  },
});
