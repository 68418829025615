import React from 'react';

import { PlumbsLogo, StandardsWithPlumbsLogo } from 'src/assets/images/logos';
import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { isStandardsDomain } from 'src/helpers';
import { isWeb } from 'src/helpers/common';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components/Link';

export const MenuLogo: React.FC = () => {
  const isSignedIn = useIsSignedIn();
  const { isPro } = useUserInfo();

  const logo =
    isPro || isStandardsDomain() ? (
      <StandardsWithPlumbsLogo width={175} height={45} aria-label="Standard’s" />
    ) : (
      <PlumbsLogo width={138} height={24} aria-label="Plumb’s" />
    );

  return isWeb ? (
    <Link to={{ route: isSignedIn ? Route.Home : Route.SignIn }} testID="home-link">
      {logo}
    </Link>
  ) : (
    logo
  );
};
