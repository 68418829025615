import * as R from 'ramda';
import React, { ComponentProps } from 'react';
import { ViewStyle, StyleProp, StyleSheet, View } from 'react-native';

import { MobileContentHeader } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  ContentConfig,
  DDxConfig,
  DrugConfig,
  DrugHandoutConfig,
  DxTxConfig,
} from 'src/features/content/config';
import { MixpanelEvent, MixpanelFeatureButton, MixpanelService } from 'src/features/tracking';
import { useScreenAccess } from 'src/hooks';
import { i18n } from 'src/locale';
import { FeaturesMenu as FeaturesMenuOld } from 'src/navigation/components/SideBar/FeaturesMenu.old';
import { screensAccess } from 'src/navigation/config/screensAccess';
import { Route } from 'src/navigation/routes';
import { palette } from 'src/styles';

import { SideBarItems } from './SideBarItems';
import { SideBarLink } from './SideBarLink';

type Item = ComponentProps<typeof SideBarLink>;

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const FeaturesMenuNew: React.FC<Props> = ({ style }) => {
  const trackMixpanelEvent = (tabName: MixpanelFeatureButton) => {
    MixpanelService.track(MixpanelEvent.FeatureButtonClicked, { 'Feature Name': tabName });
  };
  const { isPro } = useUserInfo();

  const items: Item[] = [
    {
      text: i18n.t('navigation:clinicalMonographs'),
      testID: 'nav-link-dx-tx',
      onPress: () => trackMixpanelEvent('Dx & Tx'),
      ...getContentItemProps(DxTxConfig),
    },
    {
      text: i18n.t('navigation:ddx'),
      testID: 'nav-link-ddx',
      onPress: () => trackMixpanelEvent('DDx'),
      ...getContentItemProps(DDxConfig),
    },
    {
      text: i18n.t('navigation:drugMonographs'),
      testID: 'nav-link-drugs',
      onPress: () => trackMixpanelEvent('Drugs'),
      ...getContentItemProps(DrugConfig),
    },
    {
      text: i18n.t('navigation:interactionChecker'),
      to: { route: Route.DIC },
      testID: 'nav-link-dic',
      onPress: () => trackMixpanelEvent('Interaction Checker'),
    },
    {
      text: i18n.t('navigation:algorithms'),
      testID: 'nav-link-algorithms',
      onPress: () => trackMixpanelEvent('Algorithms'),
      ...getContentItemProps(AlgorithmConfig),
    },
    {
      text: i18n.t('navigation:clinicalHandouts'),
      testID: 'nav-link-clinical-handouts',
      onPress: () => trackMixpanelEvent('Clinical Handouts'),
      ...getContentItemProps(ClinicalHandoutConfig),
    },
    {
      text: i18n.t('navigation:drugHandouts'),
      testID: 'nav-link-drug-handouts',
      onPress: () => trackMixpanelEvent('Drug Handouts'),
      ...getContentItemProps(DrugHandoutConfig),
    },
    {
      text: i18n.t('navigation:appendix'),
      testID: 'nav-link-appendix',
      onPress: () => trackMixpanelEvent('Appendix'),
      ...getContentItemProps(AppendixConfig),
    },
  ];

  const {
    isScreenAccessibleBySubscriptionStatus,
    isScreenAccessibleByPermissions,
    isScreenAccessibleBySignInState,
  } = useScreenAccess();

  const isNavLinkVisible = (route: Route) =>
    R.allPass([
      isScreenAccessibleBySignInState,
      isScreenAccessibleByPermissions,
      isScreenAccessibleBySubscriptionStatus,
    ])(screensAccess[route]);

  const standardsItems = items.filter((item) => isNavLinkVisible(item.to.route));
  const plumbsItems = items.filter((item) => !item?.pro);
  const featureList = isPro ? standardsItems : plumbsItems;

  return (
    <View style={style} testID="features-menu">
      <MobileContentHeader style={styles.sidebarHeading}>
        {i18n.t('tabNavigator:content')}
      </MobileContentHeader>
      <SideBarItems>
        {featureList.map((item, index) => (
          <SideBarLink key={item.to.route} {...item} isFirst={index === 0} />
        ))}
      </SideBarItems>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebarHeading: {
    color: palette.white,
    marginBottom: 12,
  },
});

function getContentItemProps(contentConfig: ContentConfig): Pick<Item, 'to' | 'pro'> {
  return {
    to: { route: contentConfig.routes.list },
    pro: contentConfig.isPro,
  };
}

export const FeaturesMenu: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? FeaturesMenuNew : FeaturesMenuOld;

  return <Component {...props} />;
};
