import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  DDxConfig,
  DrugConfig,
  DxTxConfig,
} from 'src/features/content/config';
import { ContentType } from 'src/features/content/constants';
import { ApplicationState } from 'src/state';

import { FlaggedFeature } from '../enums';
import {
  isUserAGroupAdmin,
  isUserAGroupMember,
  doesUserHaveLimitedProAccess,
  isUserAManagedGroupAdmin,
  isUserAManagedGroupMember,
  getPermissionsByContentType,
  isUserASelfServeInstantConversion,
} from '../helpers';
import type { GroupInfoCurrentMember, UserPermissions } from '../types';

export const useUserInfo = () => {
  const { hasAccessToEmailPetOwnerGuides: hasAccessToEmailHandouts, ...user } = useSelector(
    (state: ApplicationState) => state.auth.user,
  );

  const {
    features,
    isPro,
    isTester,
    groupInfo,
    isSharedAccount,
    inTrial,
    hasRecurlyAccount,
    expiredSubscription,
  } = user;

  const isLimitedProUser = useMemo(() => doesUserHaveLimitedProAccess(groupInfo), [groupInfo]);
  const isAdmin = useMemo(() => isUserAGroupAdmin(groupInfo), [groupInfo]);
  const isManagedGroupAdmin = useMemo(() => isUserAManagedGroupAdmin(groupInfo), [groupInfo]);
  const isIndividual = useMemo(
    () => !isUserAGroupMember(groupInfo) && !isSharedAccount,
    [groupInfo, isSharedAccount],
  );
  const isBasicSharedAccount = useMemo(() => isSharedAccount && !isPro, [isSharedAccount, isPro]);
  const hasNoAccessToAdminDashboardAfterConversion = useMemo(
    () => !isUserASelfServeInstantConversion(groupInfo),
    [groupInfo],
  );
  const hasAccessToFlaggedFeature = useCallback(
    (feature: FlaggedFeature) => isTester && features?.includes(feature),
    [isTester, features],
  );

  const hasFullAccessToBasicContentTypes = useMemo(() => !isLimitedProUser, [isLimitedProUser]);

  const userDataForContentPermissions = {
    hasAccessToFlaggedFeature,
    isBasicSharedAccount,
    isLimitedProUser,
    isProUser: isPro,
  };

  const hasFullAccessToAlgorithms = AlgorithmConfig.canBeAccessed(userDataForContentPermissions);
  const canDisplayAlgorithms = AlgorithmConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToClinicalHandouts = ClinicalHandoutConfig.canBeAccessed(
    userDataForContentPermissions,
  );
  const canDisplayClinicalHandouts = ClinicalHandoutConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToClinicalBriefs = DxTxConfig.canBeAccessed(userDataForContentPermissions);
  const canDisplayClinicalBriefs = DxTxConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToDDx = DDxConfig.canBeAccessed(userDataForContentPermissions);
  const canDisplayDDx = DDxConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToAppendix = AppendixConfig.canBeAccessed(userDataForContentPermissions);
  const canDisplayAppendix = AppendixConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToDrugs = DrugConfig.canBeAccessed(userDataForContentPermissions);
  const canDisplayDrugs = DrugConfig.canBeDisplayed(userDataForContentPermissions);

  const hasFullAccessToCoreFuncUXUpdates = true;

  const hasAccessToStudentRenewals = useMemo(
    () => hasAccessToFlaggedFeature(FlaggedFeature.StudentRenewals),
    [hasAccessToFlaggedFeature],
  );

  const hasAccessToStandards = true;

  const canManageSubscription = useMemo(
    () =>
      (isAdmin && !isManagedGroupAdmin) ||
      isIndividual ||
      !!((groupInfo as GroupInfoCurrentMember | null)?.isManagedGroup && expiredSubscription),
    [isAdmin, isManagedGroupAdmin, isIndividual, groupInfo, expiredSubscription],
  );
  const canAccessIndividualAccountFeatures = useMemo(() => !isSharedAccount, [isSharedAccount]);
  const canManageGroup = useMemo(() => isAdmin, [isAdmin]);
  const canManagePlan = useMemo(() => canManageSubscription && !inTrial, [canManageSubscription, inTrial]);

  const hasSupportStaffFeatureEnabled = useMemo(
    () => (groupInfo && isPro) || !!(isUserAManagedGroupMember(groupInfo) && groupInfo.isMixedGroup),
    [isPro, groupInfo],
  );

  const canSearchForUnavailableContentTypes = useMemo(() => !isLimitedProUser, [isLimitedProUser]);

  const permissions = useMemo(
    (): UserPermissions => ({
      hasFullAccessToBasicContentTypes,
      canDisplayClinicalBriefs,
      hasFullAccessToClinicalBriefs,
      hasAccessToStudentRenewals,
      canDisplayAlgorithms,
      hasFullAccessToAlgorithms,
      canDisplayClinicalHandouts,
      hasFullAccessToClinicalHandouts,
      canManageSubscription,
      canAccessIndividualAccountFeatures,
      canManageGroup,
      hasSupportStaffFeatureEnabled,
      hasFullAccessToDrugHandouts: true,
      canManagePlan,
      hasAccessToEmailHandouts,
      canSearchForUnavailableContentTypes,
      hasFullAccessToCoreFuncUXUpdates,
      canDisplayDDx,
      hasFullAccessToDDx,
      canDisplayAppendix,
      canDisplayDrugs,
      hasFullAccessToAppendix,
      hasFullAccessToDrugs,
      hasNoAccessToAdminDashboardAfterConversion,
      hasAccessToStandards,
    }),
    [
      hasFullAccessToBasicContentTypes,
      canDisplayClinicalBriefs,
      hasFullAccessToClinicalBriefs,
      hasAccessToStudentRenewals,
      canDisplayAlgorithms,
      hasFullAccessToAlgorithms,
      canDisplayClinicalHandouts,
      hasFullAccessToClinicalHandouts,
      canManageSubscription,
      canAccessIndividualAccountFeatures,
      canManageGroup,
      hasSupportStaffFeatureEnabled,
      canManagePlan,
      hasAccessToEmailHandouts,
      canSearchForUnavailableContentTypes,
      hasFullAccessToCoreFuncUXUpdates,
      canDisplayDDx,
      hasFullAccessToDDx,
      canDisplayAppendix,
      canDisplayDrugs,
      hasFullAccessToAppendix,
      hasFullAccessToDrugs,
      hasNoAccessToAdminDashboardAfterConversion,
      hasAccessToStandards,
    ],
  );

  const canDisplayContentType = useCallback(
    (contentType: ContentType) => permissions[getPermissionsByContentType(contentType).display],
    [permissions],
  );

  const hasAccessToContentType = useCallback(
    (contentType: ContentType) => permissions[getPermissionsByContentType(contentType).access],
    [permissions],
  );

  return {
    ...user,
    hasAccessToEmailHandouts,
    isAdmin,
    canDisplayContentType,
    hasAccessToContentType,
    permissions,
    hasRecurlyAccount,
    hasAccessToFlaggedFeature,
  };
};
