import { ItemListFetchParams, PaginationResponse } from 'src/constants/types';

export interface DrugInteractionCheckerResponse extends PaginationResponse<DrugInteraction> {
  drugs?: DICDrug[];
}

export type DICSource = 'drug' | 'navigation';

export interface DrugInteraction {
  classificationKey: DICClassificationKey;
  classification: string;
  summary: string;
  alerts: string;
  drugs: [DICDrug, DICDrug];
}

export interface DICDrug {
  id: string;
  entryTitle: string;
}

export interface ChosenDrug {
  id: string;
  title: string;
}

export enum DICClassificationKey {
  Contraindicated = 'contraindicated',
  Major = 'caution-major',
  Moderate = 'caution-moderate',
  Minor = 'caution-minor',
  NoKnownInteraction = 'no-known',
  NoData = 'no-data',
}

export type DICClassificationFilters = Exclude<DICClassificationKey, DICClassificationKey.NoData>;

export type DICMode = 'draft';

export interface DICScreenParams {
  drugs?: string[];
  mode?: DICMode;
  all?: true;
  source?: DICSource;
  classification?: DICClassificationKey;
}

export type DrugInteractionsParams = ItemListFetchParams & DICScreenParams;

export interface DICSearchScreenParams {
  selectedDrugs?: string[];
  source?: DICSource;
}

export interface InteractionClassificationData {
  classificationKey: DICClassificationKey;
  description: string;
  label: string;
  color: string;
}

export interface InteractionsCountParams {
  drugs: string[];
}

export type InteractionsCountResponse = Record<DICClassificationKey, number>;

export type DrugNamesPayload = string[];
export type DrugNamesResponse = {
  drugs: {
    id: string;
    title: string;
  }[];
};
