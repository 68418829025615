import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleProp, StyleSheet, TextStyle } from 'react-native';

import { BottomBar, Container, FullScreenTemplate, IconTextButton } from 'src/components';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { NavigationBlocker, type NavigationBlockerRef } from 'src/navigation/components/NavigationBlocker';
import { useIsFocused } from 'src/navigation/hooks/useIsFocused';
import { ScreenProps } from 'src/navigation/types';

import { NotesGroup, NotesGroupRef, RemoveNoteConfirmModal } from '../components';
import { NotesGroupHeader } from '../components/NotesGroupHeader';
import { NEW_GENERAL_NOTE_PLACEHOLDER_ID } from '../constants';
import { NotesListProvider, useNotesListContext } from '../context/NotesListContext';
import { useGroupedNotesList } from '../hooks';
import { NotesGroupType } from '../types';

type Props = ScreenProps<Route.Notes>;

interface ContentGroup {
  groupType: Exclude<NotesGroupType, NotesGroupType.General>;
  title: string;
  titleStyle?: StyleProp<TextStyle>;
}

const NoteScreenContent: React.FC<Props> = ({ navigation }) => {
  const isFocused = useIsFocused();
  const { data, isLoading, isStale, refetch, error, fetchStatus } = useGroupedNotesList();
  const { unsavedNotes, setAddingNewGeneralNote } = useNotesListContext();
  const { t } = useTranslation('notes');
  const [isWarning, setIsWarning] = useState(false);

  const navigationBlockerRef = useRef<NavigationBlockerRef>(null);
  const scrollRef = useRef<ScrollView>(null);
  const groupRefs: RefObject<NotesGroupRef>[] = [];

  useEffect(() => {
    if (isFocused && isStale) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const contentGroupsToDisplay: ContentGroup[] = [
    {
      groupType: NotesGroupType.Drug,
      title: t('drugs'),
    },
    {
      groupType: NotesGroupType.DDx,
      title: t('ddx'),
      titleStyle: styles.titleNoTransform,
    },
    {
      groupType: NotesGroupType.DxTx,
      title: t('clinicalMonograph'),
    },
    {
      groupType: NotesGroupType.Appendix,
      title: t('appendix'),
    },
  ];

  const closeAllNotes = () => {
    groupRefs.forEach((groupRef) => groupRef.current?.closeAccordion());
  };

  const handleWarningConfirm = () => {
    setIsWarning(false);
    closeAllNotes();
  };

  const handleCloseAllPress = () => {
    // we ignore general note placeholder changes since it can't be closed from outside anyway
    if (unsavedNotes.filter((id) => id !== NEW_GENERAL_NOTE_PLACEHOLDER_ID).length) {
      setIsWarning(true);
    } else {
      closeAllNotes();
    }
  };

  const handleAddNotePress = () => {
    if (isWeb) {
      setAddingNewGeneralNote(true);
    } else {
      navigation.navigate(Route.Note, { contentId: null });
    }
  };

  const createGroupRef = () => {
    const ref = createRef<NotesGroupRef>();
    groupRefs.push(ref);
    return ref;
  };

  const handleScrollToTopNative = () => {
    scrollRef.current?.scrollTo({ y: 0, animated: true });
  };

  return (
    <>
      <FullScreenTemplate title={i18n.t('screens:notes')} noScroll showBackButton isLoading={isLoading}>
        <QueryErrorBanner isDataAvailable={!!data} error={error} fetchStatus={fetchStatus} />
        {data && (
          <>
            <ScrollView ref={scrollRef}>
              <Container verticalPadding fullWidthOnMobile maxWidth="narrow">
                <NotesGroupHeader
                  title={t('general')}
                  button={
                    <IconTextButton
                      icon="plus"
                      text={t('addNote')}
                      circleIcon
                      iconPosition="right"
                      iconWidth={12}
                      style={styles.addNoteButton}
                      onPress={handleAddNotePress}
                      testID="add-note-btn"
                    />
                  }
                />
                <NotesGroup
                  title={t('general')}
                  group={data[NotesGroupType.General]}
                  groupType={NotesGroupType.General}
                  displayEmptyContentMessage
                  ref={createGroupRef()}
                />
                {contentGroupsToDisplay.map(({ groupType, title, titleStyle }) => {
                  const group = data[groupType];

                  return group ? (
                    <React.Fragment key={groupType}>
                      <NotesGroupHeader title={title} titleStyle={titleStyle} applyTopMargin />
                      <NotesGroup
                        title={title}
                        group={group}
                        groupType={groupType}
                        ref={createGroupRef()}
                      />
                    </React.Fragment>
                  ) : null;
                })}
              </Container>
            </ScrollView>
            <BottomBar
              scrollToTop={isWeb ? undefined : handleScrollToTopNative}
              showScrollToTop
              toggleItemCollapse={handleCloseAllPress}
              isAnyItemExpanded
            />
          </>
        )}
      </FullScreenTemplate>
      {!!isWarning && (
        <RemoveNoteConfirmModal
          cancel={() => setIsWarning(false)}
          type="navigation"
          confirm={handleWarningConfirm}
        />
      )}
      <NavigationBlocker isBlocked={!!unsavedNotes.length} ref={navigationBlockerRef}>
        <RemoveNoteConfirmModal
          cancel={() => navigationBlockerRef.current?.cancelNavigation()}
          confirm={() => navigationBlockerRef.current?.confirmNavigation()}
          type="navigation"
        />
      </NavigationBlocker>
    </>
  );
};

export const NotesScreen: React.FC<Props> = (props) => (
  <NotesListProvider>
    <NoteScreenContent {...props} />
  </NotesListProvider>
);

export const styles = StyleSheet.create({
  addNoteButton: {
    marginBottom: -4,
  },
  titleNoTransform: {
    textTransform: 'none',
  },
});
